.tt_address_list .abclist {
	display : flex;
	flex-wrap: wrap;
	gap: 2em;
	margin-bottom: 100px;
}

.tt_address_list.members .vcard,
.tt_address_list.research .vcard {
	width: calc(50% - 1em);
	background-color : #fff;
	border: 1px solid #eee;
	padding: 20px;
	box-sizing: border-box;
	display : flex;
	flex-flow: row;
	gap: 2em;
}

@media screen and (max-width: $lg-break-point) {
	.tt_address_list.members .vcard,
	.tt_address_list.research .vcard {
		width: 100%;
	}
}


.tt_address_list .image {
	width: 200px;
	display : flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
}
.tt_address_list .text {
	font-size: 0.85em;
	width: calc(100% - 200px);
	display: flex;
	flex-flow: column;
}

@media screen and (max-width: $sm-break-point) {
	.tt_address_list.members > div,
	.tt_address_list.research > div {
		flex-flow: column;
	}
	.tt_address_list .text {
		width     : 100%;
	}
}

.tt_address_list .title {
	display : flex;
	flex-flow: column-reverse;
}
.frame.default .tt_address_list .title h4 {
	padding-top: 0;
}

.tt_address_list address {
	font-style: normal;
}



#content .tt_address_list .range {
	display : flex;
	justify-content: center;
}

#content .tt_address_list .range ul {
	margin: 0 auto;
	padding: 0;
}

.tt_address_list .range li {
	display: inline-block;
	list-style: none;
	background: none;
	margin: 0;
	padding: 0 .5em;
	color: #ccc;
}

.tt_address_list .abclist {
	columns: 3 220px;
	column-gap: 20px;
	line-height: 1.5;
	font-size: 14px;
}

#content .tt_address_list .abclist > h4 {
	break-before: column;
	margin-top: 1em;
	margin-bottom: -1.5em;
	padding-left: 2em;
	width: 100%;
}

#content .tt_address_list .abclist p {
	break-inside: avoid;
}

#content .tt_address_list .abclist a {
	display: block;
	text-decoration: none;
	border-bottom: 0;
	margin-bottom: 4px;
}




#ttaddress__records {
	display: none;
}

#ttaddress__map {
	margin-top: 1rem;
	width: 100%;
	min-height: 600px;
}

