.pageheader {
	padding: 50px 0;
	text-align: center;
}

.pageheader h1 {

}
.pageheader h2 {
	font-weight: 400;
}
.pageheader .text {
	font-weight: 300;
	font-size: var(--size-step-4);
}





.icon.list {
	padding-bottom: 4rem;
	/*border-bottom: 1px solid #D4D4D4;*/
	margin-bottom: 4rem;
}
.pagets__index .icon.list {
	border-bottom: 1px solid #D4D4D4;
}

.icon.list .icons {
	display : flex;
	justify-content: space-around;
}

.icon.list .icon {
	display : flex;
	flex-flow: column;
	align-items: center;
	max-width: 300px;
}
.icon.list .icon img {
	height: 150px;
	width: auto;
}
.icon.list .icon .desc {
	max-width: 200px;
	text-align : center;
}