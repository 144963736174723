// styles for RTE classes
.slogan {
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: var(--tracking);
    color: $fva-blue;
    text-align: center;
    padding-bottom: 50px;
}

@media screen and (max-width: $md-break-point) {
    .slogan {
        font-size: 1.75rem;
    }
}

h2 .btn-primary {
    font-size: var(--size-step-1);
}

.btn-red {
    background-color: red;
    color: white;
    border-radius: 2em;
    padding: .33em 2.5em;
    font-size : 1.45rem;
}

.red {
    color: #f00;
}
