footer .row.menus {
	background-color: #EEF1F5;
	color: #6e6e6e;
	padding: 50px 20px;
	line-height: 1.75;
}

footer > .menus.row > .center {
	grid-template-areas:
            "contact links events infos"
;
	grid-template-columns: 13fr 6fr 6fr 2.5fr;
	align-items: start;
}
@media screen and (max-width: $lg-break-point) {
	footer > .row.menus > .center {
		grid-template-areas:
            "contact"
            "links"
            "events"
            "infos"
	;
		grid-auto-flow: row;
		grid-template-columns: auto;
	}
	footer > .row.menus .links,
	footer > .row.menus .events,
	footer > .row.menus .infos {
		display : none;
	}
}

footer > .row.menus p {
	font-size: 1rem;
}

footer > .row.menus ul {
	list-style: none;
}
footer > .row.menus p a,
footer > .row.menus ul li a {
	font-size: 1rem;
	text-decoration : none;
	color: inherit
}
footer > .row.menus a:hover {
	color: #282828;
}
