/*--------------------------------------------------------------
CSS Reset
--------------------------------------------------------------*/

img {
	display: block;
	border: 0;
	max-width: 100%;
	height: auto;
}

figure {
	margin: 0;
}
figure a {
	display : block;
}

.caption {
	padding-top: .25em;
	padding-bottom: .75em;
	text-align: center;
	font-style: italic;
	font-size: 0.8em;
}


/*--------------------------------------------------------------
Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
}


/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
textarea {
	/*
	color: #404040;
	font-family: 'Helvetica', Arial, sans-serif;
	font-size: 18px;
	line-height: 1.5;
	*/
}




/* Promo section */

.logo,
.site-title {
	text-align: center;
}

.logo {
	font-size: 8em;
}

.site-title {
	margin-top: -1em;
	margin-bottom: 3em;
}

/* Splash section */

.splash {
	background: #FFF6E5;
	padding-bottom: 2em;
}



.grey-bg {
	background-color: lightgrey;
}

.splash-content,
.more-content,
.frame-default {
	padding: 1.5rem 0;
}
@media screen and (max-width: $xl-break-point) {
	.splash-content,
	.more-content,
	.frame-default {
		padding: 1.5rem;
	}
}


blockquote {
	background: lightgrey;
	margin: 0;
	padding: 40px;
}

blockquote p {
	font-family: "Georgia", serif;
	font-size: 28px;
}
blockquote p, blockquote cite {
	display: block;
	margin: 0 auto;
	max-width: 680px;
	text-align: center;
}
blockquote cite {
	opacity: 0.5;
	padding-top: 1rem;
}



@media screen and (min-width: $md-break-point + 1px) {
	.splash-text {
		columns: 2;
		column-gap: 2em;
	}

	.splash-text p {
		padding-top: 0;
	}
}

/* Buckets section */

.buckets {
	padding: 1.5rem;
	background: #3E454C;
}

.buckets ul {
	margin: 0;
	padding: 0;
}

.buckets li {
	background: white;
	list-style-type: none;
}

.bucket {
	padding: 1.5em;
}

/* More section */

.orange-bg .more {
	display : inline-block;
	margin: 1em 0;
	padding: .5em 0;
	color: inherit;
	text-decoration : none;
	position : relative;
}

.orange-bg .more:before {
	content: "";
	position : absolute;
	top: -3px;
	background-color : #fff;
	width: 40px;
	height: 3px;
	transition: .1s width ease-in;
}

.orange-bg .more:after {
	content: "";
	margin-left: .25em;
	display : inline-block;
	width: 1.5em;
	height: 1em;
	position : relative;
	bottom: -1px;
}
.orange-bg  a.more:hover {
}

.orange-bg a.more:hover:before {
	width: 100%;
}





:root {
	--colsize: 640px;
	--cs2: calc( var(--colsize) * 0.38125);
	--cs4: calc( var(--colsize) * 0.7625);
	--cs6: calc( var(--colsize) * 1.23125);
	--cs8: calc( var(--colsize) * 1.525);

}

/* Twins section */

.twins > *:first-child:not(.grid) {
	width: 100%;
	max-width: calc( calc(var(--colsize) * 2) - 3rem);
	margin-left: auto;
	margin-right: auto;
}

.orange-bg .grid {
	background: #FFB94D;
	color: white;
}
.orange-bg .twin .txt,
.orange-bg .twin .frame-type-text,
.default .twin.col-1 .frame-type-text {
	padding: 2rem;
}

.twins .txt,
.twins .frame-type-text {
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
}

.twins .img,
.twins .frame-type-image {
	height: 100%;
}
.twins .img figure,
.twins .frame-type-image figure {
	position: relative;
	height: 100%;
}
.twins .img img,
.twins .frame-type-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.twins .img figure .caption,
.twins .frame-type-image figure .caption {
	position: absolute;
	top: 100%;
	padding: .25em;
	color: initial;
}
.twins.txt-img .img figure .caption,
.twins.frame-type-textpic .img figure .caption {
	right: 0;
}

@media screen and (min-width: $md-break-point + 1) and (max-width: $xl-break-point) {
	.twin+.twin .frame-default {
		padding-left: 0;
	}
}




/* Colophon section */
.colophon {
	padding: 2em;
	background: hsl(0, 0%, 10%);
	color: white;
	text-align: center;
}

.colophon a {
	color: white;
}

.colophon a:hover,
.colophon a:focus {
	border-bottom: 1px solid white;
}

/*--------------------------------------------------------------
If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/
@supports not (display: grid) {
	.grid {
		max-width: calc(var(--colsize) * 2);
		margin: 0 auto;
	}
}

/*--------------------------------------------------------------
Use flex to create a three-bucket layout
--------------------------------------------------------------*/

@media screen and (min-width: $md-break-point + 1px) {
	@supports (display: flex) {

		.buckets ul {
			display: flex;
			gap: 1.5rem;
		}

		.buckets li {
			width: 33%;
		}

	}
}

/*--------------------------------------------------------------
CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/


@media screen and (min-width: $md-break-point + 1px) {
	/* Layout with CSS Grid */
	@supports (display: grid) {

		/* Four-column layout. Two center columns have a total max width of 50em: */
		.grid {

			display: grid;
			grid-template-columns: 1fr repeat(2, minmax(auto, var(--colsize))) 1fr;
			grid-gap: 4rem;
		}
		.aspect-40-60 .grid {
			grid-template-columns: 1fr minmax(auto, var(--cs4)) minmax(auto, var(--cs6)) 1fr;
		}
		.aspect-60-40 .grid {
			grid-template-columns: 1fr minmax(auto, var(--cs6)) minmax(auto, var(--cs4)) 1fr;
		}
		.aspect-80-20 .grid {
			grid-template-columns: 1fr minmax(auto, var(--cs8)) minmax(auto, var(--cs2)) 1fr;
		}
		.triplets .grid {
			grid-template-columns: 1fr 1fr 1fr;
		}
		.quads .grid {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}



			/* Center items by placing them in the two center columns: */
		.splash-content,
		.frame-default,
		.more-content,
		.buckets ul {
			grid-column: 2/4;
		}

		/* Use automatic grid placement + span to let each item span two columns:*/
		.twin,
		.colophon aside {
			grid-column: span 2;
		}
		.twins.txt-img .txt {
			grid-column: 2;
		}
		.twins.img-txt .txt {
			grid-column: 1 span;
		}

		.aspect-80-20 .grid .col-2 {
			margin-left: 4rem;
		}
	}
}



.align-left {
	width: 50%;
	margin-top: .25em;
	margin-right: 1em;
	float: left;
}

.align-right {
	margin-top: .25em;
	margin-left: 1em;
	float: right;
}

.align-left.half,
.align-right.half {
	width: 50%;
}
.align-left.phi,
.align-right.phi {
	width: 40%;
}


@media screen and (min-width: 1024px + 1px) {

	.align-left.half-bleed {
		width: 75%;
		margin-left: -25%;
		float: left;
	}
	.align-left.half-bleed .caption {
		text-align: right;
	}

	.align-right.half-bleed {
		width: 75%;
		margin-right: -25%;
		float: right;
	}
	.align-right.half-bleed .caption {
		text-align: left;
	}


	.align-half,
	.align-full,
	.align-full-left,
	.align-full-right,
	.align-half-left,
	.align-half-right {
		margin-left: calc(((100vw - (2* var(--colsize)) + 3rem) / 2) * -1);
		width: 100vw;
		display: grid;
		grid-template-columns:
	  [full-start] minmax(1em, 1fr)
	  [wide-start] minmax(0, 1fr)
	  [content-start] minmax(0, calc( (2* var(--colsize)) + 3rem)) [content-end]
	  minmax(0, 1fr) [wide-end]
	  minmax(1em, 1fr) [full-end];
	}

	.align-half > * {
		grid-column: wide;
	}

	.align-full > * {
		grid-column: full;
	}

	.align-full-left {
		width: calc(100vw - 6rem);
	}

	.align-full-left a {
		grid-column: full-start/content-end;
	}

	.align-full-left .caption {
		grid-column: content-start/content-end;
		text-align: right;
	}

	.align-full-right {
		padding-left: 4.5rem;
		width: calc(100vw - 3rem);
	}

	.align-full-right a {
		grid-column: content-start/full-end;
		grid-row: 1;
	}

	.align-full-right .caption {
		grid-column: content-start/full-end;
		align-self: end;
		text-align: left;
		padding-right: 1em;
	}

	.align-half-left a {
		grid-column: full-start/wide-end;
	}

	.align-half-left .caption {
		grid-column: full-start/wide-end;
		grid-row: 2;
		text-align: right;
	}
	.align-half-right a {
		grid-column: wide-start/full-end;
	}

	.align-half-right .caption {
		grid-column: wide-start/full-end;
		grid-row: 2;
		text-align: left;
	}
}

@media screen and (max-width: 1024px) {

	.align-left.half-bleed {
		width: 100%;
	}

	.align-right.half-bleed {
		width: 100%;
	}

	.align-half,
	.align-full,
	.align-full-left,
	.align-full-right,
	.align-half-left,
	.align-half-right {
		margin-left: -1.5rem;
		width: 100vw;
	}
}

@media screen and (min-width: 1680px + 1px) {
	.align-full-left {
		width: calc(100vw - 6rem);
	}

	.align-full-left a {
		grid-column: full-start/content-end;
		margin-bottom: 1.25em;
	}

	.align-full-left .caption {
		grid-column: content-end/full-end;
		align-self: end;
		text-align: left;
		padding-left: 1em;
		padding-bottom: 1.25em;
	}

	.align-full-right {
		padding-left: 4.5rem;
		width: calc(100vw - 3rem);
	}

	.align-full-right a {
		grid-column: content-start/full-end;
		grid-row: 1;
		margin-bottom: 1.25em;
	}

	.align-full-right .caption {
		grid-column: full-start/content-start;
		grid-row: 1;
		align-self: end;
		text-align: right;
		padding-right: 1em;
		padding-bottom: 1.25em;
	}

}



/* ---------------------------------------------------------------------------- */ /* copyright hover */


.ce-image .image .imgwrap,
.ce-textpic .image .imgwrap {
	position: relative;
	display: block;
	align-items: center;
	top: 0;
	object-fit: contain;
}


.news-img > a,
.ce-image .image .imgwrap > a,
.ce-textpic .image .imgwrap > a,
.ce-image .image .imgwrap > img,
.ce-textpic .image .imgwrap > img {
	display: block;
	position: relative;
}

.media-element .copy,
.news-img .copy,
.hero.copy,
.ce-image .image .imgwrap .copy,
.ce-textpic .image .imgwrap .copy {
	position: absolute;
	right: 0;
	bottom: 0;
	font-size: 12px;
	width: 100%;
	text-align: right;
	z-index: 1;
	mix-blend-mode: difference;
	color: #fff;
}

.copy .trigger {
	display : inline-block;
	padding: 2px 6px 2px 4px;
	opacity: 1;
}
.copy .data {
	position: absolute;
	width: auto;
	max-width: 85%;
	bottom: 0;
	right: 0;
	padding: 2px 6px 2px 4px;
	opacity: 0;
	background-color : #8888;
}
@media only screen and (max-width: 991px) {
	.copy .data {
		max-width: 96%;
	}
}

.copy:hover {
	mix-blend-mode: unset;
	transition: .2s mix-blend-mode ease-in-out;
}
.copy:hover .trigger,
.copy:hover .trigger {
	opacity: 0;
	transition: .2s opacity ease-in-out;
}

.copy:hover .data,
.copy:hover .data {
	opacity: 1;
	transition: .2s opacity ease-in-out;
}

