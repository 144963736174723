/* ------------------------------------------------------------------------- */ /* header */


#header {
	background:#fff;
	color: #cfcfcf;
	width:100%;
	position:fixed;
	top:0;
	z-index:2;
	display: flex;
	flex-flow: column;
}
.sticky #header {
	background-color: #fff;
	box-shadow: 0 0 32px #0001;
}

@media screen and (max-width: $lg-break-point) {
	#header {
		overflow: hidden;
	}
}


.header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 20px 0;
	/*transition: padding .2s ease-in-out;*/
}

.sticky .header {
	padding: 0;
}

@media screen and (max-width: $sm-break-point) {
	.header {
		/*padding: 10px 0;*/
	}
}


