/* ------------------------------------------------------------------------- */ /* main navigation: son of suckerfish dropdowns */

nav * :focus { outline: 0; }

nav ul {
	list-style: none;
	padding: 0;
	border: 0;
	margin: 0;
}
nav li a {
	display: block;
	font-weight: 500;
	text-decoration: none;
	padding: 0.5em 1.25em;
}
.sticky nav li a {
	color: #626262;
}

nav li a[href^="http"] span::after {
	content: "";
	display : inline-block;
	width: 1em;
	height: 1.2em;
	-webkit-mask-position: center bottom;
	mask-position: center bottom;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-size: 50%;
	mask-size: 50%;
	padding: 0 .75rem 0 0;
	position: relative;
	bottom: -1px;
	background-color: #888;
	transition: background-color .2s ease-in-out;
	-webkit-mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
	mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
	-webkit-mask-size: 45%;
	mask-size: 45%;

}


@media (min-width: $lg-break-point) {
	header #nav {
		position : relative;
		width: calc(100% - 200px);
		margin-top: 80px;
		transition: .2s all ease-in-out;
	}
	.sticky header #nav {
		margin-top: 15px;
	}

	nav ul {
		list-style: none;
		/* background: #f6f6f6; */
		margin-right: 1em;
		padding: 0;
		border: 0;
		display: flex;
		justify-content: start;
		flex-flow: row;
	}

	nav li {
		padding: 0;
		/* text-align: center; */
		line-height: 50px;
	}


	nav li a {
		display: block;
		color: #4d4d4d;
		font-weight: 700;
		text-decoration: none;
		padding: 0 1em;
		font-size: 0.925rem;
		font-weight: 500;
		word-break: keep-all;
		text-align: center;
		height: 50px;
	}
	.sticky nav ul li a {
		color: #4d4d4d;
	}
	.sticky nav ul li li a {
		color: #626262;
	}

	nav li ul {
		display: block;
		position: absolute;
		left: -999em;
		/* left: auto; /* debug */
		height: auto;
		width: 16em;
		font-weight: normal;
		margin: 0;
		padding: 0;
		background-color: #626262;
		opacity: 0;
		/* box-shadow: 0 0 1px rgba(0,0,0,0.25); */
	}
	.no-touch  nav li ul {
		transition: all .25s ease-in-out;
	}

	nav li li {
		margin: 0;
		padding-right: 0;
		/*width: 17em;*/
		line-height: 39px;
	}
	.sticky nav li li {
		line-height: 35px;
	}

	nav li li:last-of-type {
		border-bottom: 0;
	}

	nav li ul a {
		margin-top: 0;
		border-top: 0;
		border-bottom: 0;
		padding: .75em 1em;
		height: unset;
		color: #fff;
		font-weight: 400;
		line-height: 1.5;
		text-align: left;
	}
	nav li ul a:hover {
		background-color: #fefefe;
	}
	nav li ul a span {
		position : relative;
		transition: width .25s ease;
	}
	nav li ul ul {
		margin: -1.25em 0 0 7.5em;
	}

	nav li:hover ul ul, nav li:hover ul ul ul {
		left: -999em;
	}

	nav li:hover ul, nav li li:hover ul, nav li li li:hover ul {
		left: auto;
		opacity: 1;
		box-shadow: 8px 12px 24px rgba(0,0,0,0.2);
	}

	nav li:hover {
		background: #626262;
	}
	nav li:hover a,
	.sticky nav li:hover a {
		color: #fff;
	}
	nav li.active {
		/*background: #fff;*/
	}
	.sticky #nav > ul > li.active > a {
		color: $fva-orange;
	}
	.sticky #nav > ul > li.active > a:hover {
		color: #fff;
		background: #626262;
	}
	nav li.active a {
		color: $fva-orange;
		margin-top: -1px;
		height: 50px;
	}

	nav li.active li a {
		color: #fff;
		margin-top: 0;
		border-top: 0;
		height: unset;
	}
	nav li.active li.active a {
		background: #fff;
		color: #626262;
	}

	nav li a:hover {
		background-color: #626262;
		color: #fff;
		transition: all .05s ease-in-out;
		text-decoration : none;

	}

	.sticky nav li:hover li a:hover,
	nav li li a:hover {
		background-color: #fff;
		color: #626262;
		transition: all .1s ease-in-out;
	}
}


@media screen and (max-width: $lg-break-point) {
	nav li.login {
		border-top: 1px solid #c3c3c3;
		margin: 0 1em;
	}
	nav li.login a {
		margin-left: 1rem;
		padding: 0.5em 0.25em;
	}
	nav ul li#langswitch a {
		width: 100%;
		text-align : center;
	}
}
@media screen and (min-width: $lg-break-point + 1) {
	nav li.login {
		border-left: 1px solid #fffc;
		margin-left: 1rem;
		transition: all .2s ease-in-out;
	}
	.sticky nav li.login {
		border-left: 1px solid #555;
	}
	nav li.login:hover {
		background: none;
		color: inherit;
	}
	.sticky nav li.login:hover a:not(:hover) {
		color: #838383;
	}
	nav li.login a {
		margin-left: 1rem;
	}
	nav li#langswitch {
		margin-left: 1.5rem
	}
}


#langswitch a {
	display: block;
	text-decoration: none;
	letter-spacing: 0;
	font-size: 0.8rem;
	/*
		&:before {
			font-family: Icons;
			font-style: normal;
			font-weight: 400;
			text-decoration: inherit;
			text-align: center;
			content: "\f0ac";
			padding-right: 4px;
		}
	*/
}

@media screen and (max-width: $lg-break-point) {
	nav ul {
		margin-top: 110px;
		font-size: 18px;
	}
	.sticky.menu-open nav ul {
		margin-top : 120px;
	}

	header #dl-menu #nav {
		max-width: 480px;
		background: #000;
		min-height: 100vh;
	}

	.dd #nav > ul {
		margin-left: 0;
	}
	#nav > ul > li {
		display: block;
		float: left;
		overflow: hidden;
	}
	#nav ul {
		margin-left: 0;
		display: block;
		height: unset;
		width: 100%;
		list-style: none;
	}
	.loggedin #nav ul{
		margin-top: 120px;
	}
	nav li a {
		color: $fva-gray-light;
	}
	nav li a:hover {
		color: $fva-gray;
	}
	#nav ul li {
		float: none;
		padding: 0 0 0 0;
		text-transform: none;
		text-align: left;
		line-height: unset;
	}
	header #nav ul li:last-child {
		border-bottom: 0;
	}

	#nav ul li a {
		display: inline-block;
		margin: 4px 0;
		/* border-top: 1px solid #dcdcdc; */
		width: calc( 100% - 60px );
		text-decoration: none;
		box-sizing: border-box;
	}

	#nav ul ul {
		position: relative;
		max-height: 0;
		overflow: hidden;
		transition: all .5s ease-in-out;
		left: auto;
		opacity: 1;
		padding: 0;
		box-shadow: unset;
		margin: 0;
	}

	#nav ul ul li {
		display: block;
	}

	#nav ul ul li a {
		padding: 4px 10px 4px 40px;
		/* border-bottom: 1px solid #efefef; */
		width: 100%;
		text-transform: unset;
		text-align: left;
		font-size: inherit;
		color: #838383;
	}
	#nav ul ul li:first-child a {
		border-top: 0;
	}
	#nav ul ul li:last-child {
		margin-bottom: 10px;
	}

	#nav ul ul ul {
		padding: 0 0 0 40px;
		max-height: unset;
	}
	#nav ul ul ul a {
	}
	#nav ul ul ul ul {
		display: none;
	}

	#nav ul li span.trigger {
		display: inline-block;
		width: 20px;
		height: 30px;
		cursor: pointer;
		background-position: center;
		position: relative;
		top: 6px;
		transition: all .125s ease-in-out;
		padding: 0 15px;
		background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30.729" height="30.729" style="enable-background:new 0 0 30.729 30.729" xml:space="preserve"><path style="fill:%23626262;" d="M24.813 15.366 10.185 29.997a2.494 2.494 0 0 1-1.768.731 2.5 2.5 0 0 1-1.769-4.267l11.095-11.096L6.649 4.268A2.501 2.501 0 0 1 10.185.732l14.628 14.634z"/></svg>'); /* found here: https://www.svgrepo.com/vectors/arrow/ */
		background-repeat: no-repeat;
		background-size: 14px auto;
	}
	#nav ul li span.trigger.active {
		transform: rotate(90deg);
	}

	#nav ul li span.trigger.active + ul {
		display: block;
		margin: 0 0 10px;
		max-height: 100vh;
	}

	#nav ul li.icon-arrow-small_solid_invers,
	#nav ul li.icon-arrow-small_solid {
		background: none;
	}
}
@media screen and (max-width: $sm-break-point) {
	nav ul {
		margin-top : 110px;
	}
	.sticky.menu-open nav ul {
		margin-top : 80px;
	}
}




#nav ul.secondary {
	margin-top: 40px;
	display : flex;
	flex-flow: column;
	gap: 2px;
	opacity: 1;
	transition: .2s all ease-in-out;
}
@media screen and (min-width: $lg-break-point + 1px) {
	#nav ul.secondary {
		margin-top: 0;
		position : absolute;
		top: -90px;
		right: 0;
		gap: 6px .25em;
	}
	.sticky #nav ul.secondary {
		opacity: 0;
		visibility: hidden;
	}
}


nav ul.secondary li {
	display : block;
	line-height: 38px;
	margin: 0;
}
@media screen and (min-width: $lg-break-point + 1px) and (max-width: $xl-break-point - 100px) {
	nav ul.secondary {
		flex-flow: row;
	}
	nav ul.secondary li {
		display : inline-block;
	}
}

nav ul.secondary li a {
	height: 38px;
	box-shadow: 0 0 12px rgba(0,0,0,0.05);
}
@media screen and (max-width: $lg-break-point) {
	#nav ul.secondary li a {
		width: 100%;
		height: inherit;
		margin: 0;
	}
}

nav ul.secondary li.themis a {
	background-color: #e8e8e8;
	border: 1px solid #e8e8e8;
}
nav ul.secondary li.themis a:hover {
	background-color: #fff;
	color : #4d4d4d;
	border: 1px solid #ccc;
}
nav ul.secondary li.member a {
	background-color : #F6B958;
	border: 1px solid #F6B958;
	color : #fff;
}
nav ul.secondary li.member a:hover {
	background-color : #fff;
	color : #F6B958;
}
nav ul.secondary li.newsletter a {
	background-color : #9CAABB;
	border: 1px solid #9CAABB;
	color : #fff;
}
nav ul.secondary li.newsletter a:hover {
	background-color : #fff;
	color : #9CAABB;
}