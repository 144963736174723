#logo {
	position: relative;
	z-index: 123;
}

#logo > a {
	position: relative;
	top: 70px;
	display: inline-block;
	background: transparent url(../img/FVA-Logo-2020.svg) center center / 100% auto no-repeat;
	border: none;
	width: 100px;
	height: 50px;
	text-indent: -1000%;
	overflow: hidden;
	transition: all .2s ease-in-out;
	filter: brightness(100%) saturate(0) invert(0) sepia(0) saturate(0) hue-rotate(0) brightness(100%) contrast(100%);
}
.sticky #logo a {
	top: 8px;
	width: 100px;
	height: 50px;
	background-image: url(../img/FVA-Logo-2020.svg);
}

@media screen and (max-width: $xl-break-point) {
	#logo {
		padding: 0 20px;
	}
	#logo > a {
		top: 5px;
	}
	.sticky #logo a {
		top: 2px;
	}
}