footer .row.newsletter {
	background-color: #fcfcfc;
	color: #4D4D4D;
	line-height: 1.75;
	padding: 0 20px;
}

#footer .row.newsletter .center > div {
	border-top: 1px solid #d8d8d8;
	padding: 50px 0;
}