section+hr  {
	margin: 2rem 0 1rem;
}
section+hr+h3  {
	margin: 1rem 0 2rem;
}

/* ------------------------------------------------------------------------- */

.full-height {
	height: 100vh;
	display : grid;
	place-items: center;
	background-color : #eee;
}

.bg-img {
	background-position: center center;
	background-size: cover;
	background-repeat : no-repeat;
}

.bg-img.fixed {
	background-attachment : fixed;
}

.bg-img .grid > div {
	background-color : #fff8;
}


/* ------------------------------------------------------------------------- */


.ce-wrapper {
	margin: 0 0 var(--space-xl);
}

@media screen and (min-width: $md-break-point + 1) {
	.ce-wrapper {
		display : flex;
		flex-flow: row;
		gap: 4em;
	}
	.ce-wrapper.grey-bg {
		gap: 2em;
	}
}

.ce-wrapper > div {
	margin: 0;
}

.ce-wrapper.grey-bg > div {
	background-color : #f6f6f6;
	border-radius: 3px;
	padding: 4em 4em 2em;
}

@media screen and (min-width: $md-break-point + 1) {
	.ce-wrapper.cols-2 > div {
		width: 50%;
	}
	.ce-wrapper.cols-3 > div {
		width: 33%;
	}
	.ce-wrapper.cols-4 > div {
		width: 25%;
	}
}

.frame-type-container {
	margin-top: 2rem;
	margin-bottom: 4rem;
}


/* ------------------------------------------------------------------------- */ /* caballero accordion collapsible */

@media screen and (max-width: $xl-break-point + 40px) {
	.accordion {
		padding: 0 20px;
	}
}

details {
	position:relative;
	overflow:hidden;
	max-width: 100%;
	--detailContentHeight:auto;
	border-top:1px solid #d8d8d8;
}
details:last-child {
	border-bottom:1px solid #d8d8d8;
}


summary {
	position:relative;
	display:block; /* turns off the open indicator on modern UA's */
	padding:1rem 3em 1em 1em;
	background-color : #fcfcfc;
	cursor : pointer;
	z-index : 1;
	transition: .2s background-color ease-in-out;
}

summary::-webkit-details-marker {
	display:none;
}

summary:hover {
	background-color : #fafafa;
}

summary:focus {
	outline:none;
}

summary:after {
	position:absolute;
	top:.8rem;
	right:1rem;
	content: '+';
	display:inline-block;
	color: inherit;
	font-size : 1.25rem;
	transition:transform 0.2s;
}

details[open]:not(.closed) > summary {
	background-color : #f8f8f8;
}

details[open]:not(.closed) > summary:after {
	transform-origin: center center;
	transform: rotateZ(45deg);
}

details > div {
	overflow:hidden; /* wrap margins */
	transition:margin-top 0.3s;
	padding: 20px;
}

details.closed > div {
	margin-top:var(--detailContentHeight);
}


/* ------------------------------------------------------------------------- */