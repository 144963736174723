/* ------------------------------------------------------------------------- */ /* responsive menu button and layer magic */

#dl-layer {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 12;
	float: none;
	width: 100%;
	height:auto; /* iOS position:fixed; elements fix (not 100%) */
	transition: all .2s ease-out;
	/*transition-delay: 1s;*/

	--dl-color:#626262;

}
@media screen and (min-width: $lg-break-point) {
	#dl-layer {
		display: none;
	}
}

.menu-open #dl-layer {
	background-color: #fff;
	height: 80px;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.0666666667);
}
.sticky.menu-open #dl-layer {
	height: 58px;
}
@media screen and (min-width: $sm-break-point + 1) and (max-width: $lg-break-point) {
	.sticky.menu-open #dl-layer {
		height: 90px;
	}
}


@media screen and (max-width: $lg-break-point) {
	#dl-menu {
		position: absolute;
		top: 36px;
		right: 20px;
		height: 42px;
		width: 42px;
		background-color: #bfbfbf44;
		border-radius: 50%;
		z-index: 23456;
		/*box-shadow: 0 0 1px rgba(44,66,12,0.75);*/
		border: 1px solid #62626211;

		cursor: pointer;
		margin: 0;
		/* background: #000; */
		transition: all 0.2s ease-in-out;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		color: #000;
	}
	.sticky #dl-menu {
		top: 5px;
	}
	.menu-open #dl-menu {
		background-color: #bfbfbf22;
		border: 1px solid #62626244;
	}

	#dl-layer button {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 20px;
		height: 14px;
		margin: 0 auto;
		border: none;
		cursor: pointer;
		color: inherit;
		font-size: 0;
		transition: 0.35s;
		background: transparent;
		border-top: 2px solid;
		border-bottom: 2px solid;
		border-color: var(--dl-color);
		outline: 0;
	}
	#dl-layer button:focus { outline: 0; }

	#dl-layer button:before,
	#dl-layer button:after {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 100%;
		height: 2px;
		background: var(--dl-color);
		content: "";
		transition: transform 0.35s;
		transform: translate(-50%, -50%);
	}
	#dl-layer button.active {
		border-color: transparent;
	}
	#dl-layer button.active:before {
		transform: translate(-50%, -50%) rotate(45deg);
		background: var(--dl-color);
	}
	#dl-layer button.active:after {
		transform: translate(-50%, -50%) rotate(-45deg);
		background: var(--dl-color);
	}

	/* menu overflow magic */
	html.menu-open {
		overflow: hidden;
	}
	body.menu-open {
		/*overflow: scroll;*/
		height: 100%;
	}
	.menu-open #nav,
	.sticky.menu-open #nav {
		position: fixed;
		top: 0;
		left: 0;
		overflow-y: auto;
		visibility: visible;
		opacity: 1;
	}
}

/* menu layer */
@media screen and (max-width: $lg-break-point) {
	#dl-menu {
		position: absolute;
		top: 27px;
		right: 15px;
	}

	header #nav {
		border: 0;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		width: 100vw;
		padding-top: 0;
		/*background-color: rgba(29,82,135,.95);*/
		background-color: #fff;
		/*visibility: hidden;*/
		opacity: 0;
		transition: height .23s ease-out;
		transition-delay: 0s;
	}
	header #nav.active {
		/*visibility: visible;*/
		opacity: 1;
		height: 100%;
		transition: height .1s ease-in;
		/*transition-delay: .2s;*/
	}
}

@media screen and (min-width: $sm-break-point + 1 ) and (max-width: $lg-break-point) {
	#dl-menu {
		position: absolute;
	}
	.sticky #dl-menu {
		top: 5px;
	}
}
