/* ------------------------------------------------------------------------- */ /* News */

.frame-type-list h2 {
	margin-bottom: 2rem;
}

.news-list-view {
	display : flex;
	flex-wrap: wrap;
	gap: 3rem 4rem;
}

@media screen and (max-width: $xl-break-point + 40px ) {
	.news-list-view {
		overflow : hidden;
	}
}


.news-list-view .entry {
	margin: 0;
	padding: 0;
	/*background-color: #fff;*/
	width: calc(50% - 2rem);
	position: relative;
	display: block;
}

@media screen and (max-width: $md-break-point) {
	.news-list-view .entry {
		width: calc(100%);
	}
}


.news-list-view .entry:first-child {
	/*margin-top: -4px;*/
}

.news-list-view .entry a {
	color: unset;
	outline: 0;
	display: flex;
	flex-flow: column;
	text-decoration : none;
}

.news-list-view .entry h2 {
	font-size: 1em;
	line-height: 1.25;
	border-bottom: 0;
	padding-bottom: 6px;
	margin-bottom: 0;
}

.frame.default .news-list-view .item h3,
.frame.default .news-list-view .entry h3 {
	font-size: 0.7em;
	font-weight : 500;
	color: #666;
	line-height: 1.25;
	padding-top: 0;
	padding-bottom: 0.25em;
	border-bottom: 0;
}

.news-list-view .entry .media-element {
	max-width: 100%;
	display: inline-block;
	overflow : hidden;
	aspect-ratio: 2/1;
	aspect-ratio: 3/1; /* TODO: add image handler and resize images of news records! */
	position : relative;
}
.selected.news-list-view .entry .media-element {
	aspect-ratio: 2/1;
}

.news-list-view .entry .media-element img {
	width: 100%;
	height: auto;
	display: block;
	transition: .2s transform ease-in-out;
}
.news-list-view .entry a:hover .media-element img {
	transform: scale(1.05);
	transform-origin: center;
}


.news-list-view .entry .desc {
	padding-top: 1em;
	padding-bottom: 0;
	max-width: 100%;
	display: inline-block;
}
.news-list-view .item .desc .date,
.news-list-view .entry .desc .date {
	font-size: 0.8em;
}
.news-list-view .item .desc .title,
.news-list-view .entry .desc .title {
	font-weight: 400;
	font-size: 1.5em;
}
.news-list-view .item .desc .subtitle,
.news-list-view .entry .desc .subtitle {
	font-weight: 400;
	font-size: 1.1em;
	padding-top: 10px;
	position: relative;
	top: -8px;
}
.news-list-view .entry:hover .desc .title {
	color: #000;
}
.news-list-view .entry .desc .teaser {
	color: #757575;
}

.news-list-view.simplelist .item {
	margin-bottom: 20px;
}

.content a.page-link:hover {
	color: #fff;
}

.news-list-view .more {
	color: #000;
	display : table;
	margin: 1rem 0 0 0;
	padding: .5rem 0 0;
	position : relative;
}

.news-list-view .more:before {
	content: "";
	position : absolute;
	top: 0;
	background-color : #F6B958;
	width: 40px;
	height: 3px;
	transition: .1s width ease-in;
}

.news-list-view .more:after {
	content: "";
	margin-left: .25em;
	display : inline-block;
	width: 1.5em;
	height: 1em;
	position : relative;
	bottom: -1px;
}


.news-list-view .entry:hover .more {
	color: #000;
}
.news-list-view .entry:hover .more:before {
	width: 100%;
}




#content ul.f3-widget-paginator {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
	margin: 4rem auto;
}

#content ul.f3-widget-paginator li {
	background: none;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
	padding: 0;
	margin: 0 4px;
	text-align: center;
}

#content ul.f3-widget-paginator li a {
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
	padding: 0;
	text-decoration : none;
	color: inherit;
	display : inline-block;
}

#content ul.f3-widget-paginator li a i {
	font-style: normal;
}

#content ul.f3-widget-paginator li.current {
	z-index: 2;
	color: #fff;
	text-decoration: none;
	background-color: #6c757d;
	width: 38px;
	height: 38px;
	line-height: 38px;
}
#content ul.f3-widget-paginator li.disabled {
	z-index: 2;
	color: #ccc;
	text-decoration: none;
}
#content ul.f3-widget-paginator li a:hover {
	z-index: 2;
	color: #6c757d;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
}





p.more {
	text-align : center;
}
p.more .link {
	display : inline-flex;
	flex-flow: column;
	margin: 0 auto 4rem;
	text-decoration : none;
	color: #000;
}

p.more .link:before {
	content: "";
	margin-bottom: .5rem;
	background-color : #F6B958;
	width: 40px;
	height: 3px;
	transition: .1s width ease-in;
}
p.more:hover .link:before {
	width: 100%;
}



#content .news-menu-view ul {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}

.news-menu-view > ul > li {
	margin-bottom: 1.5rem;
}

.news-menu-view > ul > li li {
	font-size : 0.825rem;
}

.news-menu-view ul li a {
	text-decoration : none;
}







.news-single {
}

.news-single .details {
	margin-bottom: 4px;
	font-size: 14px;
}

.news-single .news-list-category {
	margin-left: 4px;
	border-left: 1px solid #eee;
	padding-left: 8px;
}
.news-single .news-list-category span {
	margin-right: 6px;
}

.frame.default .news-single .title h1 {
	padding-top: 0;
}
.frame.default .news-single .title h2 {
	font-size: 1.4em;
	color: #666;
	line-height: 1.25;
	padding-top: 10px;
	border-bottom: 0;
}

.news-single .news-txt {
	margin: 25px 0;
}

.news-single .first.news-img {
	display: inline-block;
}

.news-single .first.news-img figure {
	margin: 5px 0;
	font-size: 0.8em;
}
.news-single .first.news-img img {
	width: 100%;
}
.news-single .news-img img {
	width: auto;
}

.news-single .news-related-wrap {
	margin-top: 32px;
	border-top: 1px solid #e6e6e6;
}

.news-single .news-related {
	margin-top: 32px;
}

.news-single .news-related h4 {
	font-size: 1.1em;
	text-transform: uppercase;
	color: #686868;
}

.news-single .news-related ul {
	list-style: none;
}



.news-single .info-sharing {
	display: block;
	padding: 25px 0;
	line-height: 26px;
	margin-top: 50px;
	border-top: 1px solid #e6e6e6;
	clear: both;
}


.news-single .info-sharing span {
	padding: 0 10px 0 0;
	position: relative;
	/* top: -4px; */
}

.news-single .info-sharing a {
	display: inline-block;
	margin: 0 8px;
	width: 26px;
	height: 26px;
	overflow: hidden;
	text-decoration: none;
	border-bottom: 0;
	opacity: 0.66;
	transition: all 0.5s;
	position: relative;
	top: 6px;
}
.news-single .info-sharing a:hover {
	opacity: 1;
}
.news-single .info-sharing a#li-sharer {
	background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title id="simpleicons-linkedin-icon">LinkedIn icon</title><path fill="%23000000" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>');
}
.news-single .info-sharing a#xi-sharer {
	background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title id="simpleicons-xing-icon">Xing icon</title><path fill="%23000000" d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z"/></svg>');
}

.content .news-menu-view ul li {
	text-indent: 0;
	margin-left: 0;
}


.news-single .news-img {
	padding-top: 25px;
	display: flex;
	position : relative;
}

.news-single .funding {
	display : flex;
	align-items: center;
	gap: 2em;
	padding: 1em;
	background-color : #fff;
	margin: 0 0 2em;
	float: right;
}

.news-single .funding img {
	height: fit-content;
}

