.tx-fp-newsletter form {
	margin-top: 1em;
}

.tx-fp-newsletter form .inline.field label {
	width: 9em;
	display: inline-block;
}

.tx-fp-newsletter form .field .label {
	font-weight : 600;
	font-size : .9em;
	display: inline-block;
}

.tx-fp-newsletter .ui.checkbox label {
	line-height: 1.33;
	font-weight: 400;
	max-width: 80%;
}
.tx-fp-newsletter .checkbox {
	margin-bottom: 1em;
}

.tx-fp-newsletter .mandatory {
	font-size: .8em;
}