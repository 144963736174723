@charset "UTF-8";
/*
 best-practice breakpoints
 */
:root {
  --color-dark:#404040;
  --color-light:#f3f3f3;
  --color-light-glare:#fff;
  --color-primary:#0042bf;
  --color-primary-glare:#d8e2f4;
  --color-secondary:#ee5141;
  --color-secondary-glare:#ffe3e5;
  --space-xs:clamp(0.75rem,0.69rem + 0.29vw,0.9375rem);
  --space-s:clamp(1rem,0.92rem + 0.39vw,1.25rem);
  --space-m:clamp(1.5rem,1.38rem + 0.58vw,1.875rem);
  --space-l:clamp(2rem,1.84rem + 0.78vw,2.5rem);
  --space-xl:clamp(3rem,2.77rem + 1.17vw,3.75rem);
  --space-2xl:clamp(4rem,3.69rem + 1.55vw,5rem);
  --space-3xl:clamp(6rem,5.53rem + 2.33vw,7.5rem);
  --space-xs-s:clamp(0.75rem,0.59rem + 0.78vw,1.25rem);
  --space-s-m:clamp(1rem,0.73rem + 1.36vw,1.875rem);
  --space-m-l:clamp(1.5rem,1.19rem + 1.55vw,2.5rem);
  --space-l-xl:clamp(2rem,1.46rem + 2.72vw,3.75rem);
  --space-l-2xl:clamp(2rem,1.07rem + 4.66vw,5rem);
  --space-xl-2xl:clamp(3rem,2.38rem + 3.11vw,5rem);
  --space-2xl-3xl:clamp(4rem,2.91rem + 5.44vw,7.5rem);
  --size-step-0: clamp(1rem, -0.104vw + 1.021rem, 0.938rem);
  --size-step-1: clamp(1.125rem, 0vw + 1.125rem, 1.125rem);
  --size-step-2: clamp(1.266rem, 0.141vw + 1.238rem, 1.35rem);
  --size-step-3: clamp(1.424rem, 0.327vw + 1.358rem, 1.62rem);
  --size-step-4: clamp(1.602rem, 0.57vw + 1.488rem, 1.944rem);
  --size-step-5: clamp(1.802rem, 0.885vw + 1.625rem, 2.333rem);
  --size-step-6: clamp(2.027rem, 1.287vw + 1.77rem, 2.799rem);
  --font-base:Open Sans, Verdana,sans-serif;
  --font-serif:Georgia,sans-serif;
  --gutter:var(--space-s-m);
  --border-radius:var(--size-step-1);
  --transition-base:250ms ease;
  --transition-movement:200ms linear;
  --transition-fade:200ms ease;
  --transition-bounce:500ms cubic-bezier(0.5,0.05,0.2,1.5);
  --tracking:-0.05ch;
  --tracking-s:-0.075ch ;
}

/*
 default FSC styles
 these styles need to be adjusted when
 Configuration/TypoScript/Constants/Plugin/0.fsc.constants.typoscript
 is adjusted
 */
/* BS default breakpoints */
/*
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);
*/
/*
 best-practice breakpoints
 */
/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-300.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-regular.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-500 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-500.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-600.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-700.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local(""), url("../fonts/open-sans-v34-latin-300italic.woff2") format("woff2"), url("../fonts/open-sans-v34-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-italic.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-500italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-500italic.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-600italic.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("../../../Fonts/open-sans-v34-latin-700italic.woff2") format("woff2"), url("../../../Fonts/open-sans-v34-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ------------------------------------------------------------------------- *\

	css layout formatting for Content First, static footer, sections, responsive! · template
	written by the left hand of mtness

	2022-02-15

*/
/* ------------------------------------------------------------------------- */ /* global layout */
* {
  margin: 0;
  padding: 0;
}

img, div {
  border: 0;
}

html {
  overflow-x: hidden; /* due to .start.deco.h1 */
  overflow-y: auto; /* TODO find fix for overlapping fixed header */
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  height: 100%;
  font-family: var(--font-base);
  font-size: var(--size-step-0);
  line-height: 1.75;
  background: #fff;
  color: #1E1B1B;
  opacity: 1;
  transition: 1s opacity;
  background: #fff;
  display: flex;
  flex-flow: column;
}

body.fadein {
  opacity: 0;
  transition: none;
}

#content {
  order: 3;
}

#footer {
  order: 4;
}

#background {
  order: 2;
}

#header {
  order: 1;
}

#background {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), url("../img/home_hero.jpg");
  background-color: #EEF1F5;
  background-position: center top 100px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 80vh;
  /*transition: .2s all ease-in-out;*/
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 320px) {
  #background {
    background-size: 200% auto;
    background-position: center top 75px;
    height: 65vh;
  }
}
@media screen and (min-width: 321px) and (max-width: 575px) {
  .pagets__index #background {
    background-size: 250% auto;
  }
  #background {
    height: 55vh;
    background-size: 260% auto;
    background-position: center top 75px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  #background {
    height: 55vh;
    background-size: 200% auto;
    background-position: center top 75px;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  #background {
    height: 60vh;
    background-size: 175% auto;
    background-position: center top 95px;
  }
  .pagets__index .parallaxBase {
    top: 60vh;
  }
  .pagets__subpage .parallaxBase {
    top: 75vh;
  }
}
@media screen and (min-width: 993px) {
  .pagets__index .parallaxBase {
    top: 80vh;
  }
  .pagets__subpage .parallaxBase {
    top: min(75vh, 820px);
  }
  #background {
    background-size: cover;
    background-position: center top 65px;
  }
  .pagets__index #background {
    background-size: cover;
  }
}
.pagets__index .parallaxBase {
  background-position: center top -10vh;
}

.pagets__plain #content {
  padding-top: 15vh;
}

#content {
  background: #fcfcfc;
}

/* ------------------------------------------------------------------------- */
.center {
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* ------------------------------------------------------------------------- */ /* sections & content */
#content {
  background: #fcfcfc;
  color: #4D4D4D;
  min-height: calc(62vh - 90px);
  padding: 0;
}

#content > div:not(.center) {
  margin: 0 -20px;
}

#content > .section:first-of-type {
  padding: 42px 0;
}

@media screen and (max-width: 768px) {
  #content > .section:first-of-type {
    padding: 25px 0 50px 0;
  }
}
.section.first h1 {
  font-size: 3rem;
  font-weight: 500;
  color: #3f6bb3;
  text-align: center;
  padding-bottom: 50px;
  text-transform: uppercase;
}

.section.first h2 {
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  color: #232323;
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .section.first h2 {
    font-size: 1.1em;
  }
}
.section.first p {
  line-height: 1.75;
  padding-bottom: 0.5em;
  max-width: 960px;
  margin: 0 auto;
}

.section.first ul {
  margin: 0 0 1em 1em;
}

.section.first ul li {
  padding-bottom: 0.5em;
}

/* ------------------------------------------------------------------------- */
.section.grey-bg {
  padding: 50px 0;
  background-color: #F2F2F2;
  text-align: center;
}

/* ------------------------------------------------------------------------- */
.section.cta {
  padding: 150px 0 50px;
  text-align: center;
  background-color: #fff;
}

.cta .frame-default a {
  position: relative;
  border: 1px solid #3f6bb3;
  border-radius: 0.5em;
  padding: 0.8em 3em;
  background-color: #3f6bb3;
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

/*
.cta .frame-default a:after {
	content: "";
	margin: 0 0 0 8px;
	display: inline-block;
	width: 12px;
	height: 12px;
	background: ;
}
*/
.cta .frame-default a:hover {
  background-color: #fff;
  color: #3f6bb3;
}

/*
.cta .frame-default a:after:hover {
	color: #F57321;
}
*/
@media screen and (max-width: 768px) {
  .cta .frame-default a {
    font-size: 1em;
    padding: 0.5em 1em;
  }
}
/* ------------------------------------------------------------------------- */ /* atf content styling */
#atf {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: max(200px, 38vh - 100px);
  text-align: center;
  color: #fff;
}

.pagets__index #atf {
  height: calc(80vh - 100px);
}

#atf h1, #atf .h1, #atf h2, #atf .h2, #atf h3, #atf .h3, #atf h4, #atf .h4 {
  padding-bottom: 1em;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .pagets__index #atf {
    /*height: unset;*/
  }
  #atf h1 {
    font-size: 24px;
    line-height: 1.33;
    padding-top: 0.5em;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 960px) {
  .pagets__index #atf {
    height: 100%;
  }
  #atf h1 {
    padding-top: 0.75em;
    padding-bottom: 0.25em;
    font-weight: 600;
  }
  #atf h1 br {
    display: none;
  }
}
@media screen and (min-width: 960px) and (max-width: 1366px) {
  #atf {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 1680px) {
  #atf h1 {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}
#atf .timer {
  line-height: 48px;
  padding-bottom: 24px;
}

#atf .atf-nav {
  display: flex;
}

@media screen and (max-width: 768px) {
  #atf .timer {
    padding-bottom: 0;
  }
  #atf .atf-nav {
    flex-flow: column-reverse;
  }
}
#atf .atf-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  height: 100%;
}

#atf .atf-nav > div {
  margin: 1rem;
  width: 28vw;
  max-width: 18rem;
  font-size: 1.33rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  border-radius: 2rem;
  /* border: 1px solid #ffffff22; */
}

@media screen and (max-width: 768px) {
  #atf .atf-nav > div {
    width: 80vw;
  }
}
#atf .atf-nav .blue {
  background-color: #3f6bb3;
}

#atf .atf-nav .red {
  background-color: #e0011a;
}

#atf .atf-nav > div:hover {
  background-color: #fff;
  color: #013B4F;
  /* border: 1px solid #ffffff33; */
}

#atf .atf-nav .blue a:hover {
  color: #3f6bb3;
}

#atf .atf-nav .red a:hover {
  color: #e0011a;
}

@media screen and (max-width: 1680px) {
  #atf .atf-nav > div {
    margin: 0.5em 1em;
  }
}
#atf h3 {
  max-width: 50vw;
  font-size: 42px;
  font-weight: normal;
  line-height: 48px;
  padding-top: 2em;
  display: none;
}

.pagets__index #atf h3 {
  display: block;
}

#atf h3 strong {
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  #atf h3 {
    display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 1680px) {
  #atf h3 {
    padding-top: 0.5em;
    max-width: 80vw;
  }
}
@media screen and (min-width: 1681px) {
  #atf h3 {
    padding-top: 0;
  }
}
/* ------------------------------------------------------------------------- */
.tm-yt h3 {
  font-weight: 600;
  font-size: 4em;
}

.tm-yt h4 {
  font-size: 2.5em;
}

.tm-yt h5 {
  padding-top: 1em;
  font-size: 1.75em;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .tm-yt h3 {
    font-size: 1.5em;
  }
  .tm-yt h4 {
    font-size: 1.25em;
  }
  .tm-yt .ce-gallery iframe {
    width: 100%;
    height: auto;
  }
  .tm-yt h5 {
    font-size: 1em;
  }
}
/* ------------------------------------------------------------------------- */ /* timer */
#timer .frame.default {
  margin-bottom: 0;
}

.timer.j-timer-first {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  font-weight: 300;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}

header #date .timer span {
  line-height: 1;
}

.timer b {
  font-weight: 100;
  line-height: 1;
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 16px 48px;
  position: relative;
}

.timer i {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
}

.timer b:not(:last-child)::before {
  content: ":";
  position: absolute;
  left: 85%;
  top: 40%;
  font-size: 3rem;
  transform: translate(15px, -50%);
}

@media (max-width: 1024px) {
  .timer.j-timer-first {
    font-size: 2em;
    margin-top: 0;
  }
  .timer b {
    padding-left: 16px;
    padding-right: 16px;
  }
  .timer i {
    font-size: 11px;
  }
  .timer b:not(:last-child)::before {
    font-size: 2rem;
  }
}
/* ------------------------------------------------------------------------- */ /* page register */
.frame-default .register h1 {
  font-size: 2em;
  text-align: left;
  padding: 2em 0;
}

.register fieldset > legend {
  padding: 1em 0;
}

/* ------------------------------------------------------------------------- */ /* programm */
.comot .conference-plan {
  padding: 50px 0;
}

/* ------------------------------------------------------------------------- */ /* bootstrap adjustments */
.login label {
  font-size: 0.8rem;
  display: inline-block;
  margin-bottom: 0.25rem;
}

.login input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%;
  padding: 0.5rem 3rem;
}

.login .forgot-pass {
  font-size: 0.75rem;
  text-decoration: underline;
}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */ /* footer */
footer {
  background: #666;
  color: #fff;
  font-size: 0.9rem;
  min-height: 90px;
  overflow: hidden;
}

footer > .row {
  display: flex;
  padding: 25px 20px;
}

footer > .row > .center {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  align-items: center;
}

footer h5 {
  font-size: var(--size-step-0);
  font-weight: normal;
  color: #4D4D4D;
}

footer .newsletter {
  grid-area: newsletter;
}

footer .contact {
  grid-area: contact;
}

footer .links {
  grid-area: links;
}

footer .events {
  grid-area: events;
}

footer .infos {
  grid-area: infos;
}

footer .copy {
  grid-area: copy;
}

footer .social {
  grid-area: social;
}

footer .legal {
  grid-area: legal;
}

@media screen and (max-width: 1320px) {
  footer > .row > .center {
    max-width: 1240px;
  }
}
footer .logos ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

footer .logos h2 { /* position: absolute; bottom: 0; right: 342px; width: 200px; */
  color: #BBBDBF;
  font-weight: normal;
  font-size: 10px;
  padding: 0 0 15px 0;
  text-align: center;
}

footer .logos li {
  display: inline-block;
  padding: 0;
}

footer .logos li a {
  display: block;
  position: relative;
  overflow: hidden;
  height: 0;
}

@media screen and (max-width: 768px) {
  footer .logos ul {
    flex-flow: column;
  }
  footer .logos li {
    margin: 1em 0;
  }
}
footer > .row.bottom > .center {
  display: grid;
  grid-template-areas: "copy social legal";
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 992px) {
  footer > .row {
    padding: 1em 0;
    text-align: center;
  }
  footer > .row.bottom > .center {
    grid-template-areas: "legal" "social" "copy";
    grid-template-columns: auto;
  }
  footer .bottom ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  footer .bottom .center > div {
    margin: 0.75em 0;
  }
}
#footer .frame {
  padding: 0;
}

footer .footer {
  line-height: 1;
}

footer .footer ul {
  list-style: none;
  margin: 0 -0.5em;
  padding: 0;
}

footer .footer li {
  display: inline;
  margin: 0 0.5em;
}

footer .footer a {
  text-decoration: none;
  color: #d7e6f8;
  /*border-bottom: 1px dotted transparent;*/
}

footer .footer a:hover {
  text-decoration: none;
  color: #fff;
  /*border-bottom: 1px solid #666;*/
}

@media screen and (max-width: 320px) {
  footer .footer .copy span:after {
    content: " FVA e.V.";
  }
  footer .footer .copy span + span {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  footer .legal ul {
    display: flex;
    justify-content: flex-end;
  }
}
footer .row.menus {
  background-color: #EEF1F5;
  color: #6e6e6e;
  padding: 50px 20px;
  line-height: 1.75;
}

footer > .menus.row > .center {
  grid-template-areas: "contact links events infos";
  grid-template-columns: 13fr 6fr 6fr 2.5fr;
  align-items: start;
}

@media screen and (max-width: 992px) {
  footer > .row.menus > .center {
    grid-template-areas: "contact" "links" "events" "infos";
    grid-auto-flow: row;
    grid-template-columns: auto;
  }
  footer > .row.menus .links,
  footer > .row.menus .events,
  footer > .row.menus .infos {
    display: none;
  }
}
footer > .row.menus p {
  font-size: 1rem;
}

footer > .row.menus ul {
  list-style: none;
}

footer > .row.menus p a,
footer > .row.menus ul li a {
  font-size: 1rem;
  text-decoration: none;
  color: inherit;
}

footer > .row.menus a:hover {
  color: #282828;
}

footer .row.newsletter {
  background-color: #fcfcfc;
  color: #4D4D4D;
  line-height: 1.75;
  padding: 0 20px;
}

#footer .row.newsletter .center > div {
  border-top: 1px solid #d8d8d8;
  padding: 50px 0;
}

#social {
  grid-area: social;
  display: flex;
  justify-content: center;
  /*padding: 2em 0;*/
}

#social.logolist ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

#social.logolist li {
  display: inline-block;
  padding: 0 5px;
  margin: 0 0.5rem;
}

#social.logolist li a {
  display: block;
  width: 20px;
  height: 20px;
  opacity: 0.66;
  transition: all 0.1s ease-out;
  overflow: hidden;
  text-indent: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

#social.logolist li a:hover {
  opacity: 1;
}

/* icons from here: https://simpleicons.org/ */
#social a[href*=facebook] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook icon</title><path style="fill:%23ffffff;" d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"/></svg>');
}

#social a[href*=instagram] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path style="fill:%23ffffff;" d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>');
}

#social a[href*=linkedin] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn icon</title><path style="fill:%23ffffff;" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>');
}

#social a[href*=twitter] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter icon</title><path style="fill:%23ffffff;"     d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/></svg>');
}

#social a[href*=youtube] {
  height: 20px;
  background-size: 20px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube icon</title><path style="fill:%23ffffff;" d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"/></svg>');
}

@media screen and (max-width: 992px) {
  footer .logos li a {
    margin: 0 auto !important;
    transform: scale(1.2);
  }
}
/* ------------------------------------------------------------------------- */ /* header */
#header {
  background: #fff;
  color: #cfcfcf;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  flex-flow: column;
}

.sticky #header {
  background-color: #fff;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.0666666667);
}

@media screen and (max-width: 992px) {
  #header {
    overflow: hidden;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  /*transition: padding .2s ease-in-out;*/
}

.sticky .header {
  padding: 0;
}

@media screen and (max-width: 575px) {
  .header {
    /*padding: 10px 0;*/
  }
}
/* ------------------------------------------------------------------------- */ /* responsive menu button and layer magic */
#dl-layer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  float: none;
  width: 100%;
  height: auto; /* iOS position:fixed; elements fix (not 100%) */
  transition: all 0.2s ease-out;
  /*transition-delay: 1s;*/
  --dl-color:#626262;
}

@media screen and (min-width: 992px) {
  #dl-layer {
    display: none;
  }
}
.menu-open #dl-layer {
  background-color: #fff;
  height: 80px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.0666666667);
}

.sticky.menu-open #dl-layer {
  height: 58px;
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .sticky.menu-open #dl-layer {
    height: 90px;
  }
}
@media screen and (max-width: 992px) {
  #dl-menu {
    position: absolute;
    top: 36px;
    right: 20px;
    height: 42px;
    width: 42px;
    background-color: rgba(191, 191, 191, 0.2666666667);
    border-radius: 50%;
    z-index: 23456;
    /*box-shadow: 0 0 1px rgba(44,66,12,0.75);*/
    border: 1px solid rgba(98, 98, 98, 0.0666666667);
    cursor: pointer;
    margin: 0;
    /* background: #000; */
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    color: #000;
  }
  .sticky #dl-menu {
    top: 5px;
  }
  .menu-open #dl-menu {
    background-color: rgba(191, 191, 191, 0.1333333333);
    border: 1px solid rgba(98, 98, 98, 0.2666666667);
  }
  #dl-layer button {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 14px;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    color: inherit;
    font-size: 0;
    transition: 0.35s;
    background: transparent;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: var(--dl-color);
    outline: 0;
  }
  #dl-layer button:focus {
    outline: 0;
  }
  #dl-layer button:before,
  #dl-layer button:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background: var(--dl-color);
    content: "";
    transition: transform 0.35s;
    transform: translate(-50%, -50%);
  }
  #dl-layer button.active {
    border-color: transparent;
  }
  #dl-layer button.active:before {
    transform: translate(-50%, -50%) rotate(45deg);
    background: var(--dl-color);
  }
  #dl-layer button.active:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    background: var(--dl-color);
  }
  /* menu overflow magic */
  html.menu-open {
    overflow: hidden;
  }
  body.menu-open {
    /*overflow: scroll;*/
    height: 100%;
  }
  .menu-open #nav,
  .sticky.menu-open #nav {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
  }
}
/* menu layer */
@media screen and (max-width: 992px) {
  #dl-menu {
    position: absolute;
    top: 27px;
    right: 15px;
  }
  header #nav {
    border: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100vw;
    padding-top: 0;
    /*background-color: rgba(29,82,135,.95);*/
    background-color: #fff;
    /*visibility: hidden;*/
    opacity: 0;
    transition: height 0.23s ease-out;
    transition-delay: 0s;
  }
  header #nav.active {
    /*visibility: visible;*/
    opacity: 1;
    height: 100%;
    transition: height 0.1s ease-in;
    /*transition-delay: .2s;*/
  }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
  #dl-menu {
    position: absolute;
  }
  .sticky #dl-menu {
    top: 5px;
  }
}
#logo {
  position: relative;
  z-index: 123;
}

#logo > a {
  position: relative;
  top: 70px;
  display: inline-block;
  background: transparent url(../img/FVA-Logo-2020.svg) center center/100% auto no-repeat;
  border: none;
  width: 100px;
  height: 50px;
  text-indent: -1000%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  filter: brightness(100%) saturate(0) invert(0) sepia(0) saturate(0) hue-rotate(0) brightness(100%) contrast(100%);
}

.sticky #logo a {
  top: 8px;
  width: 100px;
  height: 50px;
  background-image: url(../img/FVA-Logo-2020.svg);
}

@media screen and (max-width: 1280px) {
  #logo {
    padding: 0 20px;
  }
  #logo > a {
    top: 5px;
  }
  .sticky #logo a {
    top: 2px;
  }
}
/* ------------------------------------------------------------------------- */ /* main navigation: son of suckerfish dropdowns */
nav * :focus {
  outline: 0;
}

nav ul {
  list-style: none;
  padding: 0;
  border: 0;
  margin: 0;
}

nav li a {
  display: block;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5em 1.25em;
}

.sticky nav li a {
  color: #626262;
}

nav li a[href^=http] span::after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1.2em;
  -webkit-mask-position: center bottom;
  mask-position: center bottom;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  padding: 0 0.75rem 0 0;
  position: relative;
  bottom: -1px;
  background-color: #888;
  transition: background-color 0.2s ease-in-out;
  -webkit-mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
  mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

@media (min-width: 992px) {
  header #nav {
    position: relative;
    width: calc(100% - 200px);
    margin-top: 80px;
    transition: 0.2s all ease-in-out;
  }
  .sticky header #nav {
    margin-top: 15px;
  }
  nav ul {
    list-style: none;
    /* background: #f6f6f6; */
    margin-right: 1em;
    padding: 0;
    border: 0;
    display: flex;
    justify-content: start;
    flex-flow: row;
  }
  nav li {
    padding: 0;
    /* text-align: center; */
    line-height: 50px;
  }
  nav li a {
    display: block;
    color: #4d4d4d;
    font-weight: 700;
    text-decoration: none;
    padding: 0 1em;
    font-size: 0.925rem;
    font-weight: 500;
    word-break: keep-all;
    text-align: center;
    height: 50px;
  }
  .sticky nav ul li a {
    color: #4d4d4d;
  }
  .sticky nav ul li li a {
    color: #626262;
  }
  nav li ul {
    display: block;
    position: absolute;
    left: -999em;
    /* left: auto; /* debug */
    height: auto;
    width: 16em;
    font-weight: normal;
    margin: 0;
    padding: 0;
    background-color: #626262;
    opacity: 0;
    /* box-shadow: 0 0 1px rgba(0,0,0,0.25); */
  }
  .no-touch nav li ul {
    transition: all 0.25s ease-in-out;
  }
  nav li li {
    margin: 0;
    padding-right: 0;
    /*width: 17em;*/
    line-height: 39px;
  }
  .sticky nav li li {
    line-height: 35px;
  }
  nav li li:last-of-type {
    border-bottom: 0;
  }
  nav li ul a {
    margin-top: 0;
    border-top: 0;
    border-bottom: 0;
    padding: 0.75em 1em;
    height: unset;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }
  nav li ul a:hover {
    background-color: #fefefe;
  }
  nav li ul a span {
    position: relative;
    transition: width 0.25s ease;
  }
  nav li ul ul {
    margin: -1.25em 0 0 7.5em;
  }
  nav li:hover ul ul, nav li:hover ul ul ul {
    left: -999em;
  }
  nav li:hover ul, nav li li:hover ul, nav li li li:hover ul {
    left: auto;
    opacity: 1;
    box-shadow: 8px 12px 24px rgba(0, 0, 0, 0.2);
  }
  nav li:hover {
    background: #626262;
  }
  nav li:hover a,
  .sticky nav li:hover a {
    color: #fff;
  }
  nav li.active {
    /*background: #fff;*/
  }
  .sticky #nav > ul > li.active > a {
    color: #f6b958;
  }
  .sticky #nav > ul > li.active > a:hover {
    color: #fff;
    background: #626262;
  }
  nav li.active a {
    color: #f6b958;
    margin-top: -1px;
    height: 50px;
  }
  nav li.active li a {
    color: #fff;
    margin-top: 0;
    border-top: 0;
    height: unset;
  }
  nav li.active li.active a {
    background: #fff;
    color: #626262;
  }
  nav li a:hover {
    background-color: #626262;
    color: #fff;
    transition: all 0.05s ease-in-out;
    text-decoration: none;
  }
  .sticky nav li:hover li a:hover,
  nav li li a:hover {
    background-color: #fff;
    color: #626262;
    transition: all 0.1s ease-in-out;
  }
}
@media screen and (max-width: 992px) {
  nav li.login {
    border-top: 1px solid #c3c3c3;
    margin: 0 1em;
  }
  nav li.login a {
    margin-left: 1rem;
    padding: 0.5em 0.25em;
  }
  nav ul li#langswitch a {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 993px) {
  nav li.login {
    border-left: 1px solid rgba(255, 255, 255, 0.8);
    margin-left: 1rem;
    transition: all 0.2s ease-in-out;
  }
  .sticky nav li.login {
    border-left: 1px solid #555;
  }
  nav li.login:hover {
    background: none;
    color: inherit;
  }
  .sticky nav li.login:hover a:not(:hover) {
    color: #838383;
  }
  nav li.login a {
    margin-left: 1rem;
  }
  nav li#langswitch {
    margin-left: 1.5rem;
  }
}
#langswitch a {
  display: block;
  text-decoration: none;
  letter-spacing: 0;
  font-size: 0.8rem;
  /*
  	&:before {
  		font-family: Icons;
  		font-style: normal;
  		font-weight: 400;
  		text-decoration: inherit;
  		text-align: center;
  		content: "\f0ac";
  		padding-right: 4px;
  	}
  */
}

@media screen and (max-width: 992px) {
  nav ul {
    margin-top: 110px;
    font-size: 18px;
  }
  .sticky.menu-open nav ul {
    margin-top: 120px;
  }
  header #dl-menu #nav {
    max-width: 480px;
    background: #000;
    min-height: 100vh;
  }
  .dd #nav > ul {
    margin-left: 0;
  }
  #nav > ul > li {
    display: block;
    float: left;
    overflow: hidden;
  }
  #nav ul {
    margin-left: 0;
    display: block;
    height: unset;
    width: 100%;
    list-style: none;
  }
  .loggedin #nav ul {
    margin-top: 120px;
  }
  nav li a {
    color: #50484a;
  }
  nav li a:hover {
    color: #242626;
  }
  #nav ul li {
    float: none;
    padding: 0 0 0 0;
    text-transform: none;
    text-align: left;
    line-height: unset;
  }
  header #nav ul li:last-child {
    border-bottom: 0;
  }
  #nav ul li a {
    display: inline-block;
    margin: 4px 0;
    /* border-top: 1px solid #dcdcdc; */
    width: calc(100% - 60px);
    text-decoration: none;
    box-sizing: border-box;
  }
  #nav ul ul {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    left: auto;
    opacity: 1;
    padding: 0;
    box-shadow: unset;
    margin: 0;
  }
  #nav ul ul li {
    display: block;
  }
  #nav ul ul li a {
    padding: 4px 10px 4px 40px;
    /* border-bottom: 1px solid #efefef; */
    width: 100%;
    text-transform: unset;
    text-align: left;
    font-size: inherit;
    color: #838383;
  }
  #nav ul ul li:first-child a {
    border-top: 0;
  }
  #nav ul ul li:last-child {
    margin-bottom: 10px;
  }
  #nav ul ul ul {
    padding: 0 0 0 40px;
    max-height: unset;
  }
  #nav ul ul ul ul {
    display: none;
  }
  #nav ul li span.trigger {
    display: inline-block;
    width: 20px;
    height: 30px;
    cursor: pointer;
    background-position: center;
    position: relative;
    top: 6px;
    transition: all 0.125s ease-in-out;
    padding: 0 15px;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30.729" height="30.729" style="enable-background:new 0 0 30.729 30.729" xml:space="preserve"><path style="fill:%23626262;" d="M24.813 15.366 10.185 29.997a2.494 2.494 0 0 1-1.768.731 2.5 2.5 0 0 1-1.769-4.267l11.095-11.096L6.649 4.268A2.501 2.501 0 0 1 10.185.732l14.628 14.634z"/></svg>'); /* found here: https://www.svgrepo.com/vectors/arrow/ */
    background-repeat: no-repeat;
    background-size: 14px auto;
  }
  #nav ul li span.trigger.active {
    transform: rotate(90deg);
  }
  #nav ul li span.trigger.active + ul {
    display: block;
    margin: 0 0 10px;
    max-height: 100vh;
  }
  #nav ul li.icon-arrow-small_solid_invers,
  #nav ul li.icon-arrow-small_solid {
    background: none;
  }
}
@media screen and (max-width: 575px) {
  nav ul {
    margin-top: 110px;
  }
  .sticky.menu-open nav ul {
    margin-top: 80px;
  }
}
#nav ul.secondary {
  margin-top: 40px;
  display: flex;
  flex-flow: column;
  gap: 2px;
  opacity: 1;
  transition: 0.2s all ease-in-out;
}

@media screen and (min-width: 993px) {
  #nav ul.secondary {
    margin-top: 0;
    position: absolute;
    top: -90px;
    right: 0;
    gap: 6px 0.25em;
  }
  .sticky #nav ul.secondary {
    opacity: 0;
    visibility: hidden;
  }
}
nav ul.secondary li {
  display: block;
  line-height: 38px;
  margin: 0;
}

@media screen and (min-width: 993px) and (max-width: 1180px) {
  nav ul.secondary {
    flex-flow: row;
  }
  nav ul.secondary li {
    display: inline-block;
  }
}
nav ul.secondary li a {
  height: 38px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 992px) {
  #nav ul.secondary li a {
    width: 100%;
    height: inherit;
    margin: 0;
  }
}
nav ul.secondary li.themis a {
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
}

nav ul.secondary li.themis a:hover {
  background-color: #fff;
  color: #4d4d4d;
  border: 1px solid #ccc;
}

nav ul.secondary li.member a {
  background-color: #F6B958;
  border: 1px solid #F6B958;
  color: #fff;
}

nav ul.secondary li.member a:hover {
  background-color: #fff;
  color: #F6B958;
}

nav ul.secondary li.newsletter a {
  background-color: #9CAABB;
  border: 1px solid #9CAABB;
  color: #fff;
}

nav ul.secondary li.newsletter a:hover {
  background-color: #fff;
  color: #9CAABB;
}

.ce-table td, .ce-table th {
  vertical-align: top;
}

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext {
  overflow: hidden;
}

.ce-textpic {
  display: flex;
  gap: 4rem;
}

.ce-textpic.ce-above {
  flex-flow: column;
}

.ce-textpic.ce-below {
  flex-flow: column-reverse;
}

.ce-textpic.ce-left {
  flex-flow: row;
}

.ce-textpic.ce-right {
  flex-flow: row-reverse;
}

.ce-textpic .image {
  display: flex;
  min-width: 160px;
  align-items: center;
}

.ce-border img, .ce-border iframe {
  border: 2px solid black;
  padding: 0;
}

.ce-column {
  float: left;
  margin-right: 10px;
}
.ce-column:last-child {
  margin-right: 0;
}

.ce-row {
  overflow: hidden;
  margin-bottom: 10px;
}
.ce-row:last-child {
  margin-bottom: 0;
}

.ce-above .ce-bodytext {
  clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto;
}

.ce-headline-left {
  text-align: left;
}

.ce-headline-center {
  text-align: center;
}

.ce-headline-right {
  text-align: right;
}

.ce-uploads {
  margin: 0;
  padding: 0;
}
.ce-uploads li, .ce-uploads div {
  overflow: hidden;
}
.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}
.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top;
}
.ce-uploads span {
  display: block;
}

.frame-space-before-extra-small {
  margin-top: 1em;
}

.frame-space-before-small {
  margin-top: 2em;
}

.frame-space-before-medium {
  margin-top: 3em;
}

.frame-space-before-large {
  margin-top: 4em;
}

.frame-space-before-extra-large {
  margin-top: 5em;
}

.frame-space-after-extra-small {
  margin-bottom: 1em;
}

.frame-space-after-small {
  margin-bottom: 2em;
}

.frame-space-after-medium {
  margin-bottom: 3em;
}

.frame-space-after-large {
  margin-bottom: 4em;
}

.frame-space-after-extra-large {
  margin-bottom: 5em;
}

.frame-ruler-before:before {
  content: "";
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 2em;
}

.frame-ruler-after:after {
  content: "";
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 2em;
}

.frame-indent {
  margin-left: 15%;
  margin-right: 15%;
}

.frame-indent-left {
  margin-left: 33%;
}

.frame-indent-right {
  margin-right: 33%;
}

/* ------------------------------------------------------------------------- */ /* hide t3 translation anchors */
a[id^=c]:not([href]) {
  display: none;
}

/* ------------------------------------------------------------------------- */ /* default text-align */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* ------------------------------------------------------------------------- */ /* image spacing & placing */
.ce-gallery iframe {
  width: 960px;
  height: 540px;
}

.ce-gallery img {
  width: 100%;
  height: auto;
}

.ce-center img {
  margin: 0 auto;
}

/* ------------------------------------------------------------------------- */ /* paragraph & list spacing */
.frame-type-text p:not(:last-child),
.frame-type-text ul:not(:last-child),
.news-txt p:not(:last-child) {
  margin-bottom: 1rem;
}

/* ------------------------------------------------------------------------- */ /* default content spacing */
@media screen and (max-width: 1280px) {
  main > .frame.default {
    padding-left: 20px;
    padding-right: 20px;
  }
}
main .frame.default {
  padding-bottom: var(--space-xl);
}

main .twins.frame.default,
main .twins .frame.default {
  padding-bottom: 0;
}

main .frame.default.frame-space-after-extra-small {
  padding-bottom: var(--space-xs);
}

main .frame.default.frame-space-after-small {
  padding-bottom: var(--space-s);
}

main .frame.default.frame-space-after-medium {
  padding-bottom: var(--space-m);
}

main .frame.default.frame-space-after-large {
  padding-bottom: var(--space-l);
}

.frame.default *:not(a) + h1,
.frame.default *:not(a) + h2,
.frame.default *:not(a) + h3,
.frame.default *:not(a) + h4,
.frame.default *:not(a) + h5 {
  padding-top: var(--space-xl);
}

/*

.frame.default *:not(a) + p {
    padding-top: var(--space-s);
}
*/
/* ------------------------------------------------------------------------- */
#content > .frame-type-text {
  /*margin-bottom: 4rem;*/
}

/* ------------------------------------------------------------------------- */
#content .frame-type-text p.cols {
  columns: 300px 2;
  gap: 50px;
}

/* ------------------------------------------------------------------------- */ /* default link styling */
main a {
  color: #5e778f;
  text-decoration: none;
  transition: 0.2s color ease-in-out;
}

main a:hover {
  color: #E6AE67;
}

/* ------------------------------------------------------------------------- */ /* custom link styling */
main a.internal-link:before,
main a.external-link:before,
main a.external-link-new-window:before,
main p a[href*=themis-wissen]:before,
main a.mail:before,
main .ce-uploads-fileName a:before,
main a.download:before,
main a.download[href*=".pdf"]:before,
main a.download[href*=".zip"]:before,
main a.video:before,
main a.audio:before,
main a[href*="tel:"]:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1.2em;
  -webkit-mask-position: center bottom;
  mask-position: center bottom;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  padding: 0 0.75rem 0 0;
  position: relative;
  bottom: -2px;
  background-color: #73889D;
  transition: background-color 0.2s ease-in-out;
}

main a:hover:before {
  background-color: #E6AE67;
}

main a[href*=themis-wissen]:before {
  background-color: unset;
}

main a.btn.internal-link:before,
main a.btn.external-link:before,
main a.btn.external-link-new-window:before,
main p a[href*=themis-wissen]:before,
main a.btn.mail:before,
main .ce-uploads-fileName a:before,
main a.btn.download:before,
main a.btn[href*=".pdf"]:before,
main a.btn.download[href*=".pdf"]:before,
main a.btn[href*=".zip"]:before,
main a.btn.download[href*=".zip"]:before,
main a.btn.video:before,
main a.btn.audio:before,
main a.btn[href*="tel:"]:before {
  background-color: #fff;
}

main a.btn:hover:before {
  background-color: #4D4D4D;
}

main a.internal-link:before {
  -webkit-mask-image: url(../img/icons/link-solid.svg);
  mask-image: url(../img/icons/link-solid.svg);
  -webkit-mask-size: 60%;
  mask-size: 60%;
}

main a[target=_blank]:before,
main a.external-link:before,
main a.external-link-new-window:before {
  -webkit-mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
  mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

main a[href*=themis-wissen]:before {
  -webkit-mask-image: unset;
  mask-image: unset;
  background-color: #fff;
  background-image: url(../img/icons/themis.ico);
  background-position: center;
  background-repeat: no-repeat;
}

main a[href*=themis-wissen]:before:hover {
  background-color: #fff;
}

/* icons from here, btw: https://fontawesome.com/icons/file?s=regular&f=classic */
main a.mail:before {
  -webkit-mask-image: url(../img/icons/envelope-regular.svg);
  mask-image: url(../img/icons/envelope-regular.svg);
  -webkit-mask-size: 60%;
  mask-size: 60%;
  bottom: -3px;
}

main .ce-uploads-fileName a:before,
main a.download:before {
  -webkit-mask-image: url(../img/icons/arrow-down.svg);
  mask-image: url(../img/icons/arrow-down.svg);
}

main a.download:before {
  -webkit-mask-image: url(../img/icons/file-regular.svg);
  mask-image: url(../img/icons/file-regular.svg);
}

main a.download[href*=".pdf"]:before,
main a[href*=".pdf"]:before {
  -webkit-mask-image: url(../img/icons/file-pdf-regular.svg);
  mask-image: url(../img/icons/file-pdf-regular.svg);
}

main a.download[href*=".zip"]:before,
main a[href*=".zip"]:before {
  -webkit-mask-image: url(../img/icons/file-zipper-regular.svg);
  mask-image: url(../img/icons/file-zipper-regular.svg);
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

main a.video:before {
  -webkit-mask-image: url(../img/icons/file-video-regular.svg);
  mask-image: url(../img/icons/file-video-regular.svg);
}

main a.audio:before {
  -webkit-mask-image: url(../img/icons/file-audio-regular.svg);
  mask-image: url(../img/icons/file-audio-regular.svg);
}

main a[href*="tel:"]:before {
  -webkit-mask-image: url(../img/icons/phone-solid.svg);
  mask-image: url(../img/icons/phone-solid.svg);
}

/* ------------------------------------------------------------------------- */ /* lists */
#content ul {
  margin: 0;
  padding: 0 0 0 var(--space-m);
  list-style: none;
}

#content div[class*=frame-type-text] ul.cols {
  columns: 300px 2;
  gap: 50px;
}

#content div[class*=frame-type-text] ul li {
  margin: 0 0 0.5em;
  padding: 0 0 0 0.25em;
  position: relative;
  overflow: visible;
  break-inside: avoid;
}

#content div[class*=frame-type-text] ul li:before {
  content: "";
  padding: 0;
  font-size: 0.9em;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #F6B958;
  border-radius: 2px;
  position: absolute;
  top: 0.7em;
  left: -0.8em;
}

/* mt 2022-11-28
   IEos debugging,
   since mobile webkit doesn't know how to do columns with li:before
*/
@supports (-webkit-overflow-scrolling: touch) {
  #content div[class*=frame-type-text] ul.cols {
    columns: unset;
  }
  #content div[class*=frame-type-text] ul li:before {
    top: 0.6em;
  }
}
/* ------------------------------------------------------------------------- */ /* form elements */
legend {
  font-weight: 600;
}

label {
  font-size: 0.9em;
  font-weight: 600;
}

input:not([type=checkbox], [type=radio]), textarea, select {
  min-width: 10em;
  padding: 0.25em 0.5em;
}

.field {
  margin: 0 0 0.5em;
}

/* ------------------------------------------------------------------------- */ /* buttons */
a.btn {
  display: inline-block;
  background-color: #4D4D4D;
  border: 1px solid #4D4D4D;
  color: #fff;
  margin: 1em 0;
  padding: 0.5em 2em;
  text-decoration: none;
  border-radius: 3px;
  transition: 0.1s all ease-in-out;
}

a.btn:hover {
  background-color: #fff;
  color: #4D4D4D;
  border: 1px solid #4D4D4D;
}

a.btn.secondary {
  position: relative;
  border: 1px solid #3f6bb3;
  border-radius: 3px;
  padding: 0.8em 2em;
  background-color: #3f6bb3;
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

a.btn.secondary:hover {
  background-color: #fff;
  color: #3f6bb3;
}

/* ------------------------------------------------------------------------- */ /*  tables */
.contenttable {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.contenttable thead th {
  padding: 1em 2em;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  text-transform: uppercase;
  min-width: 18em;
  background-color: #ececec;
}

.contenttable.benefits thead th {
  padding: 3em;
  background-color: unset;
  border-left: 0;
}

.contenttable.benefits thead th .red {
  position: absolute;
  text-transform: initial;
  font-size: 1.2em;
  transform: translate(2em, -1.5em) rotate(-23deg);
  background-color: rgba(255, 255, 255, 0.5);
}

.contenttable.benefits thead th:not(:first-child) {
  border-top: 1px solid #ddd;
}

.contenttable thead th a {
  margin-top: 0.5em;
  text-transform: initial;
}

.contenttable th:first-child,
.contenttable td:first-child {
  width: 60%;
}

.contenttable td {
  padding: 1em 2em;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.contenttable tr:nth-child(2n) {
  background-color: #f6f6f6;
}

.contenttable td .check {
  display: block;
  margin: 0 auto;
  width: 23px;
  height: 20px;
  background-size: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='20'><path fill='%23627F15' d='M3 8l-3 4 10 8L23 3l-4-3L9 13z'/></svg>");
  text-indent: 25px;
  overflow: hidden;
}

/* ------------------------------------------------------------------------- */ /* custom background */
.ce-container.grey-bg {
  background-color: #ececec;
}

.ce-container.grey-bg .content > div {
  padding-top: var(--space-xl);
}

.ce-container.grey-bg .content > div p {
  margin-top: 1rem;
  padding-top: 1em;
}

.ce-container.orange-bg {
  background-color: #F6B958;
}

/* ------------------------------------------------------------------------- */
#content ul.typo3-messages {
  padding: 0;
}

#content ul.typo3-messages li {
  margin: 0;
  padding: 0;
}

#content ul.typo3-messages li:before {
  display: none;
}

#content ul.typo3-messages li p {
  padding: 0.75em 1em;
}

/* ------------------------------------------------------------------------- */ /* newsletter CTA */
.newsletter .frame.center p {
  text-align: center;
  font-size: 1.33rem;
}

.newsletter a.btn {
  background-color: #F6B958;
  border-color: #F6B958;
  font-size: 1rem;
}

.newsletter a.btn:hover {
  background-color: #fff;
  color: #F6B958;
}

/* ------------------------------------------------------------------------- */ /* committee portraits */
#content .portraits {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

#content .portraits h1 {
  display: block;
  width: 100%;
}

body #content .portraits .portrait {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  position: relative;
}

@media screen and (min-width: 769px) {
  body #content .portraits .portrait {
    width: 50%;
  }
}
@media screen and (min-width: 1281px) {
  body #content .portraits .portrait {
    width: 33%;
  }
}
#content .portraits .portrait img {
  width: unset;
  filter: saturate(75%) grayscale(25%);
  border-radius: 50%;
  margin-right: 20px;
}

body #content .portrait figcaption {
  display: block;
  position: absolute;
  left: 140px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  width: calc(100% - 140px);
}

/* mtness, 2019-05-23 */
/* interesting - :first-line pseudo-element doesn't work with display: flex^^ */
body #content .portrait figcaption:first-line {
  font-size: 18px;
}

/* ------------------------------------------------------------------------- */
section + hr {
  margin: 2rem 0 1rem;
}

section + hr + h3 {
  margin: 1rem 0 2rem;
}

/* ------------------------------------------------------------------------- */
.full-height {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #eee;
}

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-img.fixed {
  background-attachment: fixed;
}

.bg-img .grid > div {
  background-color: rgba(255, 255, 255, 0.5333333333);
}

/* ------------------------------------------------------------------------- */
.ce-wrapper {
  margin: 0 0 var(--space-xl);
}

@media screen and (min-width: 769px) {
  .ce-wrapper {
    display: flex;
    flex-flow: row;
    gap: 4em;
  }
  .ce-wrapper.grey-bg {
    gap: 2em;
  }
}
.ce-wrapper > div {
  margin: 0;
}

.ce-wrapper.grey-bg > div {
  background-color: #f6f6f6;
  border-radius: 3px;
  padding: 4em 4em 2em;
}

@media screen and (min-width: 769px) {
  .ce-wrapper.cols-2 > div {
    width: 50%;
  }
  .ce-wrapper.cols-3 > div {
    width: 33%;
  }
  .ce-wrapper.cols-4 > div {
    width: 25%;
  }
}
.frame-type-container {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

/* ------------------------------------------------------------------------- */ /* caballero accordion collapsible */
@media screen and (max-width: 1320px) {
  .accordion {
    padding: 0 20px;
  }
}
details {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  --detailContentHeight:auto;
  border-top: 1px solid #d8d8d8;
}

details:last-child {
  border-bottom: 1px solid #d8d8d8;
}

summary {
  position: relative;
  display: block; /* turns off the open indicator on modern UA's */
  padding: 1rem 3em 1em 1em;
  background-color: #fcfcfc;
  cursor: pointer;
  z-index: 1;
  transition: 0.2s background-color ease-in-out;
}

summary::-webkit-details-marker {
  display: none;
}

summary:hover {
  background-color: #fafafa;
}

summary:focus {
  outline: none;
}

summary:after {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  content: "+";
  display: inline-block;
  color: inherit;
  font-size: 1.25rem;
  transition: transform 0.2s;
}

details[open]:not(.closed) > summary {
  background-color: #f8f8f8;
}

details[open]:not(.closed) > summary:after {
  transform-origin: center center;
  transform: rotateZ(45deg);
}

details > div {
  overflow: hidden; /* wrap margins */
  transition: margin-top 0.3s;
  padding: 20px;
}

details.closed > div {
  margin-top: var(--detailContentHeight);
}

/* ------------------------------------------------------------------------- */
.klaro {
  font-family: inherit;
  font-size: 14px;
}

.klaro button {
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
}

.klaro.cm-as-context-notice {
  height: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
}

.klaro .cookie-modal,
.klaro .context-notice,
.klaro .cookie-notice {
  /* The switch - the box around the slider */
}

.klaro .cookie-modal .cm-switch-container,
.klaro .context-notice .cm-switch-container,
.klaro .cookie-notice .cm-switch-container {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #c8c8c8;
  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;
}

.klaro .cookie-modal .cm-switch-container:last-child,
.klaro .context-notice .cm-switch-container:last-child,
.klaro .cookie-notice .cm-switch-container:last-child {
  border-bottom: 0;
}

.klaro .cookie-modal .cm-switch-container:first-child,
.klaro .context-notice .cm-switch-container:first-child,
.klaro .cookie-notice .cm-switch-container:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch-container p,
.klaro .context-notice .cm-switch-container p,
.klaro .cookie-notice .cm-switch-container p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch,
.klaro .context-notice .cm-switch,
.klaro .cookie-notice .cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
  opacity: 0.6;
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: rgb(255, 218, 108);
  background-color: var(--green2, rgb(255, 218, 108));
  opacity: 0.8;
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: rgb(255, 218, 108);
  background-color: var(--green2, rgb(255, 218, 108));
  opacity: 0.8;
  cursor: not-allowed;
}

.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal .cm-list-input,
.klaro .context-notice .cm-list-input,
.klaro .cookie-notice .cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-title,
.klaro .context-notice .cm-list-title,
.klaro .cookie-notice .cm-list-title {
  font-size: 0.9em;
  font-weight: 600;
}

.klaro .cookie-modal .cm-list-description,
.klaro .context-notice .cm-list-description,
.klaro .cookie-notice .cm-list-description {
  color: #7c7c7c;
  color: var(--dark3, #7c7c7c);
  font-size: 0.9em;
  padding-top: 4px;
}

.klaro .cookie-modal .cm-list-label,
.klaro .context-notice .cm-list-label,
.klaro .cookie-notice .cm-list-label {
  /* The slider */
  /* Rounded sliders */
}

.klaro .cookie-modal .cm-list-label .cm-switch,
.klaro .context-notice .cm-list-label .cm-switch,
.klaro .cookie-notice .cm-list-label .cm-switch {
  position: absolute;
  left: 0;
}

.klaro .cookie-modal .cm-list-label .slider,
.klaro .context-notice .cm-list-label .slider,
.klaro .cookie-notice .cm-list-label .slider {
  background-color: #f2f2f2;
  background-color: var(--white2, #f2f2f2);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  width: 50px;
  display: inline-block;
}

.klaro .cookie-modal .cm-list-label .slider::before,
.klaro .context-notice .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-label .slider::before {
  background-color: #e6e6e6;
  background-color: var(--white3, #e6e6e6);
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  transition: 0.4s;
}

.klaro .cookie-modal .cm-list-label .slider.round,
.klaro .context-notice .cm-list-label .slider.round,
.klaro .cookie-notice .cm-list-label .slider.round {
  border-radius: 30px;
}

.klaro .cookie-modal .cm-list-label .slider.round::before,
.klaro .context-notice .cm-list-label .slider.round::before,
.klaro .cookie-notice .cm-list-label .slider.round::before {
  border-radius: 50%;
}

.klaro .cookie-modal .cm-list-label input:focus + .slider,
.klaro .context-notice .cm-list-label input:focus + .slider,
.klaro .cookie-notice .cm-list-label input:focus + .slider {
  box-shadow-color: rgb(255, 218, 108);
  box-shadow-color: var(--green3, rgb(255, 218, 108));
  box-shadow: 0 0 1px var(color, green3);
}

.klaro .cookie-modal .cm-list-label input:checked + .slider::before,
.klaro .context-notice .cm-list-label input:checked + .slider::before,
.klaro .cookie-notice .cm-list-label input:checked + .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:focus + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
  box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #000;
  text-decoration: none;
}

.klaro .cookie-modal p,
.klaro .cookie-modal strong,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice strong,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice strong,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  color: #fafafa;
  color: var(--light1, #fafafa);
}

.klaro .cookie-modal p,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 0.7em;
}

.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal h3,
.klaro .cookie-modal h4,
.klaro .cookie-modal h5,
.klaro .cookie-modal h6,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice h3,
.klaro .context-notice h4,
.klaro .context-notice h5,
.klaro .context-notice h6,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice h3,
.klaro .cookie-notice h4,
.klaro .cookie-notice h5,
.klaro .cookie-notice h6 {
  font-family: inherit;
  font-family: var(--title-font-family, inherit);
}

.klaro .cookie-modal .cm-link,
.klaro .context-notice .cm-link,
.klaro .cookie-notice .cm-link {
  margin-right: 0.5em;
  vertical-align: middle;
}

.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  color: #000;
  background-color: #5c5c5c;
  padding: 0.75em 2.5em;
  margin-right: 0.5em;
  border-style: none;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
}

.klaro .cookie-modal .cm-btn:disabled,
.klaro .context-notice .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
  opacity: 0.5;
}

.klaro .cookie-modal .cm-btn.cm-btn-close,
.klaro .context-notice .cm-btn.cm-btn-close,
.klaro .cookie-notice .cm-btn.cm-btn-close {
  background-color: #c8c8c8;
  background-color: var(--light2, #c8c8c8);
}

.klaro .cookie-modal .cm-btn.cm-btn-success,
.klaro .context-notice .cm-btn.cm-btn-success,
.klaro .cookie-notice .cm-btn.cm-btn-success {
  color: #fff;
  background-color: #1a936f;
}

.klaro .cookie-modal .cm-btn.cm-btn-success-var,
.klaro .context-notice .cm-btn.cm-btn-success-var,
.klaro .cookie-notice .cm-btn.cm-btn-success-var {
  background-color: #1a936f;
  background-color: var(--green2, #1a936f);
}

.klaro .cookie-modal .cm-btn.cm-btn-info,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-info {
  color: #888;
  background-color: #D9D9D9;
  background-color: var(--blue1, #D9D9D9);
  font-weight: inherit;
}

.klaro .cookie-modal .cm-btn.cm-btn-info:hover,
.klaro .context-notice .cm-btn.cm-btn-info:hover,
.klaro .cookie-notice .cm-btn.cm-btn-info:hover {
  color: #222;
}

.klaro .context-notice {
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  border-style: solid;
  border-style: var(--border-style, solid);
  border-width: 1px;
  border-width: var(--border-width, 1px);
  border-color: #c8c8c8;
  border-color: var(--light2, #c8c8c8);
  background-color: #fafafa;
  background-color: var(--light1, #fafafa);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
}

.klaro .context-notice.cm-dark {
  background-color: #333;
  background-color: var(--dark1, #333);
  border-color: #5c5c5c;
  border-color: var(--dark2, #5c5c5c);
}

.klaro .context-notice.cm-dark p {
  color: #fafafa;
  color: var(--light1, #fafafa);
}

.klaro .context-notice.cm-dark p a {
  color: #459cdc;
  color: var(--blue2, #459cdc);
}

.klaro .context-notice p {
  color: #333;
  color: var(--dark1, #333);
  flex-grow: 0;
  text-align: center;
  padding-top: 0;
  margin-top: 0;
}

.klaro .context-notice p a {
  color: #000;
  color: var(--green2, #000);
}

.klaro .context-notice p.cm-buttons {
  margin-top: 12px;
}

.klaro .cookie-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.klaro .cookie-modal.cm-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  z-index: 0;
}

.klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
  position: relative;
  -ms-transform: none;
  transform: none;
}

.klaro .cookie-modal .cm-bg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: #fafafa;
  color: var(--light1, #fafafa);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 90%;
  max-width: 640px;
  max-height: 90%;
  overflow: auto;
}

@media (min-width: 860px) {
  .klaro .cookie-modal .cm-modal.cm-klaro {
    margin: 0 auto;
    height: auto;
    width: auto;
  }
}
.klaro .cookie-modal .cm-modal .hide {
  border-style: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.klaro .cookie-modal .cm-modal .hide svg {
  stroke: #fafafa;
  stroke: var(--light1, #fafafa);
}

.klaro .cookie-modal .cm-modal .cm-footer {
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-footer-buttons {
  display: flex;
  flex-flow: row;
  justify-content: stretch;
}

@media (max-width: 440px) {
  .klaro .cookie-modal .cm-modal .cm-footer-buttons {
    flex-flow: column;
  }
  .klaro .cookie-modal .cm-btn.cm-btn-success, .klaro .context-notice .cm-btn.cm-btn-success, .klaro .cookie-notice .cm-btn.cm-btn-success {
    margin-bottom: 10px;
  }
}
.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
  font-size: 0.8em;
  padding-top: 4px;
  text-align: right;
  padding-right: 8px;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
}

.klaro .cookie-modal .cm-modal .cm-header {
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-color: #5c5c5c;
  border-bottom-color: var(--dark2, #5c5c5c);
  padding: 1em;
  padding-right: 24px;
}

.klaro .cookie-modal .cm-modal .cm-header h1 {
  margin: 0;
  font-size: 1.25em;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-header h1.title {
  padding-right: 20px;
}

.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul {
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body span {
  display: inline-block;
  width: auto;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
  padding: 0;
  margin: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
  margin-left: -40px;
  display: none;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
  margin-top: 10px;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
  position: relative;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 60px;
  min-height: 40px;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
  font-size: 0.8em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  padding-top: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
  font-weight: 600;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
  padding-left: 0.2em;
  font-size: 0.8em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1023px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-style: none;
    border-radius: 0;
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
  z-index: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
  padding-top: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  background-color: #fff;
  margin-bottom: 0;
  margin-right: 0;
  bottom: 0;
  padding: 0.75em 1em;
  max-width: 40vw;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
  margin-bottom: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
  text-decoration: underline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
  display: inline-block;
  flex-grow: 1;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  display: inline-block;
  margin-top: -0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
    width: 100%;
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  margin-top: 0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    width: calc(50% - 0.5em);
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  margin-top: -0.5em;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: right;
  align-items: baseline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
  margin-top: 0.5em;
}

.klaro .cookie-modal-notice {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: #fafafa;
  color: var(--light1, #fafafa);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  padding: 1em;
  padding-top: 0.2em;
}

@media (min-width: 400px) {
  .klaro .cookie-modal-notice {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    height: auto;
    width: auto;
  }
}
.klaro .cookie-modal-notice .cn-ok {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.klaro .cookie-notice-hidden {
  display: none !important;
}

.pageheader {
  padding: 50px 0;
  text-align: center;
}

.pageheader h2 {
  font-weight: 400;
}

.pageheader .text {
  font-weight: 300;
  font-size: var(--size-step-4);
}

.icon.list {
  padding-bottom: 4rem;
  /*border-bottom: 1px solid #D4D4D4;*/
  margin-bottom: 4rem;
}

.pagets__index .icon.list {
  border-bottom: 1px solid #D4D4D4;
}

.icon.list .icons {
  display: flex;
  justify-content: space-around;
}

.icon.list .icon {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 300px;
}

.icon.list .icon img {
  height: 150px;
  width: auto;
}

.icon.list .icon .desc {
  max-width: 200px;
  text-align: center;
}

/* ------------------------------------------------------------------------- */ /* News */
.frame-type-list h2 {
  margin-bottom: 2rem;
}

.news-list-view {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 4rem;
}

@media screen and (max-width: 1320px) {
  .news-list-view {
    overflow: hidden;
  }
}
.news-list-view .entry {
  margin: 0;
  padding: 0;
  /*background-color: #fff;*/
  width: calc(50% - 2rem);
  position: relative;
  display: block;
}

@media screen and (max-width: 768px) {
  .news-list-view .entry {
    width: 100%;
  }
}
.news-list-view .entry:first-child {
  /*margin-top: -4px;*/
}

.news-list-view .entry a {
  color: unset;
  outline: 0;
  display: flex;
  flex-flow: column;
  text-decoration: none;
}

.news-list-view .entry h2 {
  font-size: 1em;
  line-height: 1.25;
  border-bottom: 0;
  padding-bottom: 6px;
  margin-bottom: 0;
}

.frame.default .news-list-view .item h3,
.frame.default .news-list-view .entry h3 {
  font-size: 0.7em;
  font-weight: 500;
  color: #666;
  line-height: 1.25;
  padding-top: 0;
  padding-bottom: 0.25em;
  border-bottom: 0;
}

.news-list-view .entry .media-element {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  aspect-ratio: 2/1;
  aspect-ratio: 3/1; /* TODO: add image handler and resize images of news records! */
  position: relative;
}

.selected.news-list-view .entry .media-element {
  aspect-ratio: 2/1;
}

.news-list-view .entry .media-element img {
  width: 100%;
  height: auto;
  display: block;
  transition: 0.2s transform ease-in-out;
}

.news-list-view .entry a:hover .media-element img {
  transform: scale(1.05);
  transform-origin: center;
}

.news-list-view .entry .desc {
  padding-top: 1em;
  padding-bottom: 0;
  max-width: 100%;
  display: inline-block;
}

.news-list-view .item .desc .date,
.news-list-view .entry .desc .date {
  font-size: 0.8em;
}

.news-list-view .item .desc .title,
.news-list-view .entry .desc .title {
  font-weight: 400;
  font-size: 1.5em;
}

.news-list-view .item .desc .subtitle,
.news-list-view .entry .desc .subtitle {
  font-weight: 400;
  font-size: 1.1em;
  padding-top: 10px;
  position: relative;
  top: -8px;
}

.news-list-view .entry:hover .desc .title {
  color: #000;
}

.news-list-view .entry .desc .teaser {
  color: #757575;
}

.news-list-view.simplelist .item {
  margin-bottom: 20px;
}

.content a.page-link:hover {
  color: #fff;
}

.news-list-view .more {
  color: #000;
  display: table;
  margin: 1rem 0 0 0;
  padding: 0.5rem 0 0;
  position: relative;
}

.news-list-view .more:before {
  content: "";
  position: absolute;
  top: 0;
  background-color: #F6B958;
  width: 40px;
  height: 3px;
  transition: 0.1s width ease-in;
}

.news-list-view .more:after {
  content: "";
  margin-left: 0.25em;
  display: inline-block;
  width: 1.5em;
  height: 1em;
  position: relative;
  bottom: -1px;
}

.news-list-view .entry:hover .more {
  color: #000;
}

.news-list-view .entry:hover .more:before {
  width: 100%;
}

#content ul.f3-widget-paginator {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin: 4rem auto;
}

#content ul.f3-widget-paginator li {
  background: none;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0 4px;
  text-align: center;
}

#content ul.f3-widget-paginator li a {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

#content ul.f3-widget-paginator li a i {
  font-style: normal;
}

#content ul.f3-widget-paginator li.current {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
  width: 38px;
  height: 38px;
  line-height: 38px;
}

#content ul.f3-widget-paginator li.disabled {
  z-index: 2;
  color: #ccc;
  text-decoration: none;
}

#content ul.f3-widget-paginator li a:hover {
  z-index: 2;
  color: #6c757d;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

p.more {
  text-align: center;
}

p.more .link {
  display: inline-flex;
  flex-flow: column;
  margin: 0 auto 4rem;
  text-decoration: none;
  color: #000;
}

p.more .link:before {
  content: "";
  margin-bottom: 0.5rem;
  background-color: #F6B958;
  width: 40px;
  height: 3px;
  transition: 0.1s width ease-in;
}

p.more:hover .link:before {
  width: 100%;
}

#content .news-menu-view ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.news-menu-view > ul > li {
  margin-bottom: 1.5rem;
}

.news-menu-view > ul > li li {
  font-size: 0.825rem;
}

.news-menu-view ul li a {
  text-decoration: none;
}

.news-single .details {
  margin-bottom: 4px;
  font-size: 14px;
}

.news-single .news-list-category {
  margin-left: 4px;
  border-left: 1px solid #eee;
  padding-left: 8px;
}

.news-single .news-list-category span {
  margin-right: 6px;
}

.frame.default .news-single .title h1 {
  padding-top: 0;
}

.frame.default .news-single .title h2 {
  font-size: 1.4em;
  color: #666;
  line-height: 1.25;
  padding-top: 10px;
  border-bottom: 0;
}

.news-single .news-txt {
  margin: 25px 0;
}

.news-single .first.news-img {
  display: inline-block;
}

.news-single .first.news-img figure {
  margin: 5px 0;
  font-size: 0.8em;
}

.news-single .first.news-img img {
  width: 100%;
}

.news-single .news-img img {
  width: auto;
}

.news-single .news-related-wrap {
  margin-top: 32px;
  border-top: 1px solid #e6e6e6;
}

.news-single .news-related {
  margin-top: 32px;
}

.news-single .news-related h4 {
  font-size: 1.1em;
  text-transform: uppercase;
  color: #686868;
}

.news-single .news-related ul {
  list-style: none;
}

.news-single .info-sharing {
  display: block;
  padding: 25px 0;
  line-height: 26px;
  margin-top: 50px;
  border-top: 1px solid #e6e6e6;
  clear: both;
}

.news-single .info-sharing span {
  padding: 0 10px 0 0;
  position: relative;
  /* top: -4px; */
}

.news-single .info-sharing a {
  display: inline-block;
  margin: 0 8px;
  width: 26px;
  height: 26px;
  overflow: hidden;
  text-decoration: none;
  border-bottom: 0;
  opacity: 0.66;
  transition: all 0.5s;
  position: relative;
  top: 6px;
}

.news-single .info-sharing a:hover {
  opacity: 1;
}

.news-single .info-sharing a#li-sharer {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title id="simpleicons-linkedin-icon">LinkedIn icon</title><path fill="%23000000" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>');
}

.news-single .info-sharing a#xi-sharer {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title id="simpleicons-xing-icon">Xing icon</title><path fill="%23000000" d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z"/></svg>');
}

.content .news-menu-view ul li {
  text-indent: 0;
  margin-left: 0;
}

.news-single .news-img {
  padding-top: 25px;
  display: flex;
  position: relative;
}

.news-single .funding {
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  background-color: #fff;
  margin: 0 0 2em;
  float: right;
}

.news-single .funding img {
  height: fit-content;
}

.tx-fp-newsletter form {
  margin-top: 1em;
}

.tx-fp-newsletter form .inline.field label {
  width: 9em;
  display: inline-block;
}

.tx-fp-newsletter form .field .label {
  font-weight: 600;
  font-size: 0.9em;
  display: inline-block;
}

.tx-fp-newsletter .ui.checkbox label {
  line-height: 1.33;
  font-weight: 400;
  max-width: 80%;
}

.tx-fp-newsletter .checkbox {
  margin-bottom: 1em;
}

.tx-fp-newsletter .mandatory {
  font-size: 0.8em;
}

.tt_address_list .abclist {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  margin-bottom: 100px;
}

.tt_address_list.members .vcard,
.tt_address_list.research .vcard {
  width: calc(50% - 1em);
  background-color: #fff;
  border: 1px solid #eee;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  gap: 2em;
}

@media screen and (max-width: 992px) {
  .tt_address_list.members .vcard,
  .tt_address_list.research .vcard {
    width: 100%;
  }
}
.tt_address_list .image {
  width: 200px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.tt_address_list .text {
  font-size: 0.85em;
  width: calc(100% - 200px);
  display: flex;
  flex-flow: column;
}

@media screen and (max-width: 575px) {
  .tt_address_list.members > div,
  .tt_address_list.research > div {
    flex-flow: column;
  }
  .tt_address_list .text {
    width: 100%;
  }
}
.tt_address_list .title {
  display: flex;
  flex-flow: column-reverse;
}

.frame.default .tt_address_list .title h4 {
  padding-top: 0;
}

.tt_address_list address {
  font-style: normal;
}

#content .tt_address_list .range {
  display: flex;
  justify-content: center;
}

#content .tt_address_list .range ul {
  margin: 0 auto;
  padding: 0;
}

.tt_address_list .range li {
  display: inline-block;
  list-style: none;
  background: none;
  margin: 0;
  padding: 0 0.5em;
  color: #ccc;
}

.tt_address_list .abclist {
  columns: 3 220px;
  column-gap: 20px;
  line-height: 1.5;
  font-size: 14px;
}

#content .tt_address_list .abclist > h4 {
  break-before: column;
  margin-top: 1em;
  margin-bottom: -1.5em;
  padding-left: 2em;
  width: 100%;
}

#content .tt_address_list .abclist p {
  break-inside: avoid;
}

#content .tt_address_list .abclist a {
  display: block;
  text-decoration: none;
  border-bottom: 0;
  margin-bottom: 4px;
}

#ttaddress__records {
  display: none;
}

#ttaddress__map {
  margin-top: 1rem;
  width: 100%;
  min-height: 600px;
}

/*--------------------------------------------------------------
CSS Reset
--------------------------------------------------------------*/
img {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

figure a {
  display: block;
}

.caption {
  padding-top: 0.25em;
  padding-bottom: 0.75em;
  text-align: center;
  font-style: italic;
  font-size: 0.8em;
}

/*--------------------------------------------------------------
Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar */
}

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
textarea {
  /*
  color: #404040;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  */
}

/* Promo section */
.logo,
.site-title {
  text-align: center;
}

.logo {
  font-size: 8em;
}

.site-title {
  margin-top: -1em;
  margin-bottom: 3em;
}

/* Splash section */
.splash {
  background: #FFF6E5;
  padding-bottom: 2em;
}

.grey-bg {
  background-color: lightgrey;
}

.splash-content,
.more-content,
.frame-default {
  padding: 1.5rem 0;
}

@media screen and (max-width: 1280px) {
  .splash-content,
  .more-content,
  .frame-default {
    padding: 1.5rem;
  }
}
blockquote {
  background: lightgrey;
  margin: 0;
  padding: 40px;
}

blockquote p {
  font-family: "Georgia", serif;
  font-size: 28px;
}

blockquote p, blockquote cite {
  display: block;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
}

blockquote cite {
  opacity: 0.5;
  padding-top: 1rem;
}

@media screen and (min-width: 769px) {
  .splash-text {
    columns: 2;
    column-gap: 2em;
  }
  .splash-text p {
    padding-top: 0;
  }
}
/* Buckets section */
.buckets {
  padding: 1.5rem;
  background: #3E454C;
}

.buckets ul {
  margin: 0;
  padding: 0;
}

.buckets li {
  background: white;
  list-style-type: none;
}

.bucket {
  padding: 1.5em;
}

/* More section */
.orange-bg .more {
  display: inline-block;
  margin: 1em 0;
  padding: 0.5em 0;
  color: inherit;
  text-decoration: none;
  position: relative;
}

.orange-bg .more:before {
  content: "";
  position: absolute;
  top: -3px;
  background-color: #fff;
  width: 40px;
  height: 3px;
  transition: 0.1s width ease-in;
}

.orange-bg .more:after {
  content: "";
  margin-left: 0.25em;
  display: inline-block;
  width: 1.5em;
  height: 1em;
  position: relative;
  bottom: -1px;
}

.orange-bg a.more:hover:before {
  width: 100%;
}

:root {
  --colsize: 640px;
  --cs2: calc( var(--colsize) * 0.38125);
  --cs4: calc( var(--colsize) * 0.7625);
  --cs6: calc( var(--colsize) * 1.23125);
  --cs8: calc( var(--colsize) * 1.525);
}

/* Twins section */
.twins > *:first-child:not(.grid) {
  width: 100%;
  max-width: calc(var(--colsize) * 2 - 3rem);
  margin-left: auto;
  margin-right: auto;
}

.orange-bg .grid {
  background: #FFB94D;
  color: white;
}

.orange-bg .twin .txt,
.orange-bg .twin .frame-type-text,
.default .twin.col-1 .frame-type-text {
  padding: 2rem;
}

.twins .txt,
.twins .frame-type-text {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.twins .img,
.twins .frame-type-image {
  height: 100%;
}

.twins .img figure,
.twins .frame-type-image figure {
  position: relative;
  height: 100%;
}

.twins .img img,
.twins .frame-type-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.twins .img figure .caption,
.twins .frame-type-image figure .caption {
  position: absolute;
  top: 100%;
  padding: 0.25em;
  color: initial;
}

.twins.txt-img .img figure .caption,
.twins.frame-type-textpic .img figure .caption {
  right: 0;
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
  .twin + .twin .frame-default {
    padding-left: 0;
  }
}
/* Colophon section */
.colophon {
  padding: 2em;
  background: hsl(0deg, 0%, 10%);
  color: white;
  text-align: center;
}

.colophon a {
  color: white;
}

.colophon a:hover,
.colophon a:focus {
  border-bottom: 1px solid white;
}

/*--------------------------------------------------------------
If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/
@supports not (display: grid) {
  .grid {
    max-width: calc(var(--colsize) * 2);
    margin: 0 auto;
  }
}
/*--------------------------------------------------------------
Use flex to create a three-bucket layout
--------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  @supports (display: flex) {
    .buckets ul {
      display: flex;
      gap: 1.5rem;
    }
    .buckets li {
      width: 33%;
    }
  }
}
/*--------------------------------------------------------------
CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  /* Layout with CSS Grid */
  @supports (display: grid) {
    /* Four-column layout. Two center columns have a total max width of 50em: */
    .grid {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, var(--colsize))) 1fr;
      grid-gap: 4rem;
    }
    .aspect-40-60 .grid {
      grid-template-columns: 1fr minmax(auto, var(--cs4)) minmax(auto, var(--cs6)) 1fr;
    }
    .aspect-60-40 .grid {
      grid-template-columns: 1fr minmax(auto, var(--cs6)) minmax(auto, var(--cs4)) 1fr;
    }
    .aspect-80-20 .grid {
      grid-template-columns: 1fr minmax(auto, var(--cs8)) minmax(auto, var(--cs2)) 1fr;
    }
    .triplets .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .quads .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    /* Center items by placing them in the two center columns: */
    .splash-content,
    .frame-default,
    .more-content,
    .buckets ul {
      grid-column: 2/4;
    }
    /* Use automatic grid placement + span to let each item span two columns:*/
    .twin,
    .colophon aside {
      grid-column: span 2;
    }
    .twins.txt-img .txt {
      grid-column: 2;
    }
    .twins.img-txt .txt {
      grid-column: 1 span;
    }
    .aspect-80-20 .grid .col-2 {
      margin-left: 4rem;
    }
  }
}
.align-left {
  width: 50%;
  margin-top: 0.25em;
  margin-right: 1em;
  float: left;
}

.align-right {
  margin-top: 0.25em;
  margin-left: 1em;
  float: right;
}

.align-left.half,
.align-right.half {
  width: 50%;
}

.align-left.phi,
.align-right.phi {
  width: 40%;
}

@media screen and (min-width: 1025px) {
  .align-left.half-bleed {
    width: 75%;
    margin-left: -25%;
    float: left;
  }
  .align-left.half-bleed .caption {
    text-align: right;
  }
  .align-right.half-bleed {
    width: 75%;
    margin-right: -25%;
    float: right;
  }
  .align-right.half-bleed .caption {
    text-align: left;
  }
  .align-half,
  .align-full,
  .align-full-left,
  .align-full-right,
  .align-half-left,
  .align-half-right {
    margin-left: calc((100vw - 2 * var(--colsize) + 3rem) / 2 * -1);
    width: 100vw;
    display: grid;
    grid-template-columns: [full-start] minmax(1em, 1fr) [wide-start] minmax(0, 1fr) [content-start] minmax(0, calc(2 * var(--colsize) + 3rem)) [content-end] minmax(0, 1fr) [wide-end] minmax(1em, 1fr) [full-end];
  }
  .align-half > * {
    grid-column: wide;
  }
  .align-full > * {
    grid-column: full;
  }
  .align-full-left {
    width: calc(100vw - 6rem);
  }
  .align-full-left a {
    grid-column: full-start/content-end;
  }
  .align-full-left .caption {
    grid-column: content-start/content-end;
    text-align: right;
  }
  .align-full-right {
    padding-left: 4.5rem;
    width: calc(100vw - 3rem);
  }
  .align-full-right a {
    grid-column: content-start/full-end;
    grid-row: 1;
  }
  .align-full-right .caption {
    grid-column: content-start/full-end;
    align-self: end;
    text-align: left;
    padding-right: 1em;
  }
  .align-half-left a {
    grid-column: full-start/wide-end;
  }
  .align-half-left .caption {
    grid-column: full-start/wide-end;
    grid-row: 2;
    text-align: right;
  }
  .align-half-right a {
    grid-column: wide-start/full-end;
  }
  .align-half-right .caption {
    grid-column: wide-start/full-end;
    grid-row: 2;
    text-align: left;
  }
}
@media screen and (max-width: 1024px) {
  .align-left.half-bleed {
    width: 100%;
  }
  .align-right.half-bleed {
    width: 100%;
  }
  .align-half,
  .align-full,
  .align-full-left,
  .align-full-right,
  .align-half-left,
  .align-half-right {
    margin-left: -1.5rem;
    width: 100vw;
  }
}
@media screen and (min-width: 1681px) {
  .align-full-left {
    width: calc(100vw - 6rem);
  }
  .align-full-left a {
    grid-column: full-start/content-end;
    margin-bottom: 1.25em;
  }
  .align-full-left .caption {
    grid-column: content-end/full-end;
    align-self: end;
    text-align: left;
    padding-left: 1em;
    padding-bottom: 1.25em;
  }
  .align-full-right {
    padding-left: 4.5rem;
    width: calc(100vw - 3rem);
  }
  .align-full-right a {
    grid-column: content-start/full-end;
    grid-row: 1;
    margin-bottom: 1.25em;
  }
  .align-full-right .caption {
    grid-column: full-start/content-start;
    grid-row: 1;
    align-self: end;
    text-align: right;
    padding-right: 1em;
    padding-bottom: 1.25em;
  }
}
/* ---------------------------------------------------------------------------- */ /* copyright hover */
.ce-image .image .imgwrap,
.ce-textpic .image .imgwrap {
  position: relative;
  display: block;
  align-items: center;
  top: 0;
  object-fit: contain;
}

.news-img > a,
.ce-image .image .imgwrap > a,
.ce-textpic .image .imgwrap > a,
.ce-image .image .imgwrap > img,
.ce-textpic .image .imgwrap > img {
  display: block;
  position: relative;
}

.media-element .copy,
.news-img .copy,
.hero.copy,
.ce-image .image .imgwrap .copy,
.ce-textpic .image .imgwrap .copy {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  width: 100%;
  text-align: right;
  z-index: 1;
  mix-blend-mode: difference;
  color: #fff;
}

.copy .trigger {
  display: inline-block;
  padding: 2px 6px 2px 4px;
  opacity: 1;
}

.copy .data {
  position: absolute;
  width: auto;
  max-width: 85%;
  bottom: 0;
  right: 0;
  padding: 2px 6px 2px 4px;
  opacity: 0;
  background-color: rgba(136, 136, 136, 0.5333333333);
}

@media only screen and (max-width: 991px) {
  .copy .data {
    max-width: 96%;
  }
}
.copy:hover {
  mix-blend-mode: unset;
  transition: 0.2s mix-blend-mode ease-in-out;
}

.copy:hover .trigger,
.copy:hover .trigger {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.copy:hover .data,
.copy:hover .data {
  opacity: 1;
  transition: 0.2s opacity ease-in-out;
}

.slogan {
  font-size: 2.25rem;
  font-weight: 600;
  letter-spacing: var(--tracking);
  color: #3f6bb3;
  text-align: center;
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .slogan {
    font-size: 1.75rem;
  }
}
h2 .btn-primary {
  font-size: var(--size-step-1);
}

.btn-red {
  background-color: red;
  color: white;
  border-radius: 2em;
  padding: 0.33em 2.5em;
  font-size: 1.45rem;
}

.red {
  color: #f00;
}