
// styles for mask elements
// @import "content/<elementname>";

// @import "content/pageheader";


// font-sizes found in rte.scss

/* ------------------------------------------------------------------------- */ /* hide t3 translation anchors */

a[id^="c"]:not([href]) {
    display: none;
}

/* ------------------------------------------------------------------------- */ /* default text-align */

.text-left { text-align: left }
.text-right { text-align: right }
.text-center { text-align: center }

/* ------------------------------------------------------------------------- */ /* image spacing & placing */

.ce-gallery iframe {
    width: 960px;
    height: 540px;
}

.ce-gallery img {
    width: 100%;
    height: auto;
}

.ce-center img {
    margin: 0 auto;
}

/* ------------------------------------------------------------------------- */ /* paragraph & list spacing */

.frame-type-text p:not(:last-child),
.frame-type-text ul:not(:last-child),
.news-txt p:not(:last-child) {
    margin-bottom: 1rem;
}

/* ------------------------------------------------------------------------- */ /* default content spacing */

@media screen and (max-width: $xl-break-point) {
    main > .frame.default {
        padding-left: 20px;
        padding-right: 20px;
    }
}

main .frame.default {
    padding-bottom: var(--space-xl);
}
main .twins.frame.default,
main .twins .frame.default {
    padding-bottom: 0;
}
main .frame.default.frame-space-after-extra-small {
    padding-bottom: var(--space-xs);
}
main .frame.default.frame-space-after-small {
    padding-bottom: var(--space-s);
}
main .frame.default.frame-space-after-medium {
    padding-bottom: var(--space-m);
}
main .frame.default.frame-space-after-large {
    padding-bottom: var(--space-l);
}

.frame.default *:not(a) + h1,
.frame.default *:not(a) + h2,
.frame.default *:not(a) + h3,
.frame.default *:not(a) + h4,
.frame.default *:not(a) + h5 {
    padding-top: var(--space-xl);
}
/*

.frame.default *:not(a) + p {
    padding-top: var(--space-s);
}
*/

/* ------------------------------------------------------------------------- */

#content > .frame-type-text {
    /*margin-bottom: 4rem;*/
}

/* ------------------------------------------------------------------------- */

#content .frame-type-text p.cols {
    columns: 300px 2;
    gap: 50px;
}

/* ------------------------------------------------------------------------- */ /* default link styling */

main a {
    color: #5e778f;
    text-decoration: none;
    transition: .2s color ease-in-out;
}
main a:hover {
    color: #E6AE67;
}

/* ------------------------------------------------------------------------- */ /* custom link styling */

main a.internal-link:before,
main a.external-link:before,
main a.external-link-new-window:before,
main p a[href*="themis-wissen"]:before,
main a.mail:before,
main .ce-uploads-fileName a:before,
main a.download:before,
main a.download[href*=".pdf"]:before,
main a.download[href*=".zip"]:before,
main a.video:before,
main a.audio:before,
main a[href*="tel:"]:before {
    content: "";
    display : inline-block;
    width: 1em;
    height: 1.2em;
    -webkit-mask-position: center bottom;
    mask-position: center bottom;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 50%;
    mask-size: 50%;
    padding: 0 .75rem 0 0;
    position: relative;
    bottom: -2px;
    background-color: #73889D;
    transition: background-color .2s ease-in-out;
}
main a:hover:before {
    background-color: #E6AE67;
}
main a[href*="themis-wissen"]:before {
    background-color : unset;
}


main a.btn.internal-link:before,
main a.btn.external-link:before,
main a.btn.external-link-new-window:before,
main p a[href*="themis-wissen"]:before,
main a.btn.mail:before,
main .ce-uploads-fileName a:before,
main a.btn.download:before,
main a.btn[href*=".pdf"]:before,
main a.btn.download[href*=".pdf"]:before,
main a.btn[href*=".zip"]:before,
main a.btn.download[href*=".zip"]:before,
main a.btn.video:before,
main a.btn.audio:before,
main a.btn[href*="tel:"]:before {
    background-color : #fff;
}
main a.btn:hover:before {
    background-color: #4D4D4D;
}


main a.internal-link:before {
    -webkit-mask-image: url(../img/icons/link-solid.svg);
    mask-image: url(../img/icons/link-solid.svg);
    -webkit-mask-size: 60%;
    mask-size: 60%;
}
main a[target="_blank"]:before,
main a.external-link:before,
main a.external-link-new-window:before {
    -webkit-mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
    mask-image: url(../img/icons/arrow-up-right-from-square-solid.svg);
    -webkit-mask-size: 45%;
    mask-size: 45%;
}

main a[href*="themis-wissen"]:before {
    -webkit-mask-image: unset;
    mask-image: unset;
    background-color : #fff;
    background-image: url(../img/icons/themis.ico);
    background-position : center;
    background-repeat : no-repeat;
}
main a[href*="themis-wissen"]:before:hover {
    background-color : #fff;

}

/* icons from here, btw: https://fontawesome.com/icons/file?s=regular&f=classic */

main a.mail:before {
    -webkit-mask-image: url(../img/icons/envelope-regular.svg);
    mask-image: url(../img/icons/envelope-regular.svg);
    -webkit-mask-size: 60%;
    mask-size: 60%;
    bottom: -3px;
}

main .ce-uploads-fileName a:before,
main a.download:before {
    -webkit-mask-image: url(../img/icons/arrow-down.svg);
    mask-image: url(../img/icons/arrow-down.svg);
}

main a.download:before {
    -webkit-mask-image: url(../img/icons/file-regular.svg);
    mask-image: url(../img/icons/file-regular.svg);
}

main a.download[href*=".pdf"]:before,
main a[href*=".pdf"]:before {
    -webkit-mask-image: url(../img/icons/file-pdf-regular.svg);
    mask-image: url(../img/icons/file-pdf-regular.svg);
}

main a.download[href*=".zip"]:before,
main a[href*=".zip"]:before {
    -webkit-mask-image: url(../img/icons/file-zipper-regular.svg);
    mask-image: url(../img/icons/file-zipper-regular.svg);
    -webkit-mask-size: 45%;
    mask-size: 45%;
}

main a.video:before {
    -webkit-mask-image: url(../img/icons/file-video-regular.svg);
    mask-image: url(../img/icons/file-video-regular.svg);
}

main a.audio:before {
    -webkit-mask-image: url(../img/icons/file-audio-regular.svg);
    mask-image: url(../img/icons/file-audio-regular.svg);
}

main a[href*="tel:"]:before {
    -webkit-mask-image: url(../img/icons/phone-solid.svg);
    mask-image: url(../img/icons/phone-solid.svg);
}


/* ------------------------------------------------------------------------- */ /* lists */

#content ul {
    margin: 0;
    padding: 0 0 0 var(--space-m);
    list-style: none;
}


#content div[class*="frame-type-text"] ul.cols {
    columns: 300px 2;
    gap: 50px;
}

#content div[class*="frame-type-text"] ul li {
    margin: 0 0 .5em;
    padding: 0 0 0 .25em;
    position : relative;
    overflow : visible;
    break-inside: avoid;
}
#content div[class*="frame-type-text"] ul li:before {
    content: "";
    padding: 0;
    font-size: 0.9em;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #F6B958;
    border-radius: 2px;
    position: absolute;
    top: .7em;
    left: -.8em;
}

/* mt 2022-11-28
   IEos debugging,
   since mobile webkit doesn't know how to do columns with li:before
*/
@supports (-webkit-overflow-scrolling: touch) {
    #content div[class*="frame-type-text"] ul.cols {
        columns: unset;
    }
    #content div[class*="frame-type-text"] ul li:before {
        top: .6em;
    }
}

/* ------------------------------------------------------------------------- */ /* form elements */

legend {
    font-weight: 600;
}

label {
    font-size: 0.9em;
    font-weight: 600;
}

input:not([type=checkbox],[type=radio]), textarea, select {
    min-width: 10em;
    padding: .25em .5em;
}

.field {
    margin: 0 0 .5em;
}

/* ------------------------------------------------------------------------- */ /* buttons */

a.btn {
    display : inline-block;
    background-color: #4D4D4D;
    border: 1px solid #4D4D4D;
    color: #fff;
    margin: 1em 0;
    padding: .5em 2em;
    text-decoration : none;
    border-radius: 3px;
    transition: .1s all ease-in-out;
}
a.btn:hover {
    background-color: #fff;
    color: #4D4D4D;
    border: 1px solid #4D4D4D;
}

a.btn.secondary {
    position: relative;
    border: 1px solid $fva-blue;
    border-radius: 3px;
    padding: .8em 2em;
    background-color: $fva-blue;
    text-decoration: none;
    color: #fff;
    font-size: 1.2em;
    font-weight: 600;
    transition: all .2s ease-in-out;
}

a.btn.secondary:hover {
    background-color: #fff;
    color: $fva-blue;
}

/* ------------------------------------------------------------------------- */ /*  tables */

.contenttable {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.contenttable thead th {
    padding: 1em 2em;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    text-transform : uppercase;
    min-width: 18em;
    background-color: #ececec;
}
.contenttable.benefits thead th {
    padding: 3em;
    background-color: unset;
    border-left: 0;
}

.contenttable.benefits thead th .red {
    position : absolute;
    text-transform : initial;
    font-size : 1.2em;
    transform: translate(2em, -1.5em) rotate(-23deg);
    background-color : rgba(255,255,255,0.5);
}


.contenttable.benefits thead th:not(:first-child) {
    border-top: 1px solid #ddd;
}

.contenttable thead th a {
    margin-top: .5em;
    text-transform: initial;
}


.contenttable th:first-child,
.contenttable td:first-child {
    width: 60%;
}

.contenttable td {
    padding: 1em 2em;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.contenttable tr:nth-child(2n) {
    background-color : #f6f6f6;
}

.contenttable td .check {
    display: block;
    margin: 0 auto;
    width: 23px;
    height: 20px;
    background-size: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='20'><path fill='%23627F15' d='M3 8l-3 4 10 8L23 3l-4-3L9 13z'/></svg>");
    text-indent : 25px;
    overflow : hidden;
}

/* ------------------------------------------------------------------------- */ /* custom background */

.ce-container.grey-bg {
    background-color : #ececec;
}
.ce-container.grey-bg .content > div {
    padding-top: var(--space-xl);
}
.ce-container.grey-bg .content > div p {
    margin-top: 1rem;
    padding-top: 1em;
}

.ce-container.orange-bg {
    background-color : #F6B958;
}

/* ------------------------------------------------------------------------- */

#content ul.typo3-messages {
    padding: 0;
}
#content ul.typo3-messages li {
    margin: 0;
    padding: 0;
}
#content ul.typo3-messages li:before {
    display : none;
}
#content ul.typo3-messages li p {
    padding: .75em 1em;
}

/* ------------------------------------------------------------------------- */ /* newsletter CTA */

.newsletter .frame.center p {
    text-align : center;
    font-size : 1.33rem;
}

.newsletter a.btn {
    background-color : #F6B958;
    border-color: #F6B958;
    font-size : 1rem;
}
.newsletter a.btn:hover {
    background-color : #fff;
    color: #F6B958;
}


/* ------------------------------------------------------------------------- */ /* committee portraits */

#content .portraits {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}
#content .portraits h1 {
    display: block;
    width: 100%;
}
body #content .portraits .portrait {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    position: relative;
}
@media screen and (min-width: $md-break-point + 1px) {
    body #content .portraits .portrait {
        width : 50%;
    }
}
@media screen and (min-width: $xl-break-point + 1px) {
    body #content .portraits .portrait {
        width : 33%;
    }
}

#content .portraits .portrait img {
    width: unset;
    filter: saturate(75%) grayscale(25%);
    border-radius: 50%;
    margin-right: 20px;
}
body #content .portrait figcaption {
    display: block;
    position: absolute;
    left: 140px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    width: calc(100% - 140px);
}

/* mtness, 2019-05-23 */
/* interesting - :first-line pseudo-element doesn't work with display: flex^^ */
body #content .portrait figcaption:first-line {
    font-size: 18px;
}

/* ------------------------------------------------------------------------- */
